import { IntlShape } from 'react-intl';

import { DataPointObject } from '../../../../../../shared/components/time-series-chart';

import { DraftDataPoint } from './edit-data-track-modal.definitions';

export const convertDataPointsToDraft = (dataPoints: DataPointObject[]): DraftDataPoint[] =>
  dataPoints.map((dt) => ({
    timeStamp: dt.ts,
    value: dt.v.toString(),
    isTimeStampDirty: false,
    isValueDirty: false,
  }));

export const convertDraftToDataPoints = (draft: DraftDataPoint[]): DataPointObject[] =>
  draft.map((draftDataPoint) => ({
    ts: draftDataPoint.timeStamp,
    v: Number(draftDataPoint.value),
  }));

export const createDraftDataPoint = (): DraftDataPoint => {
  const ts = new Date();
  ts.setMilliseconds(0);

  return { timeStamp: ts.getTime(), value: '', isValueDirty: false, isTimeStampDirty: false };
};

export const updateDataHandler = (
  prev: DraftDataPoint[],
  rowIndex: number,
  columnId: string,
  value: unknown,
) =>
  prev.map((row, index) => {
    // only update the changed index
    if (rowIndex !== index) {
      return row;
    }

    // value was changed
    if (columnId === ('value' satisfies keyof DraftDataPoint) && typeof value === 'string') {
      return { ...row, value, isValueDirty: true };
    }

    // ts was changed
    if (columnId === ('timeStamp' satisfies keyof DraftDataPoint) && value instanceof Date) {
      return { ...row, timeStamp: value.getTime(), isTimeStampDirty: true };
    }

    return row;
  });

export const deleteDataHandler = (prev: DraftDataPoint[]) => {
  const penultimateIndex = -1;
  return prev.slice(0, penultimateIndex);
};

export const addDataPointHandler = (prev: DraftDataPoint[]) => prev.concat(createDraftDataPoint());

export const checkForDuplicates = (draft: DraftDataPoint[], intl: IntlShape) => {
  const duplicateDataPoints: [DraftDataPoint, number][] = draft.flatMap((dp, index) =>
    draft.filter((otherDp) => otherDp.timeStamp === dp.timeStamp).length > 1 ? [[dp, index]] : [],
  );

  const duplicateIndexes = duplicateDataPoints.map(([_, index]) => index);

  const duplicatesErrorMessage = intl.formatMessage({
    description: 'Duplicate rows error message',
    id: 'w0hFQn',
    defaultMessage: 'One or more rows have duplicate timestamps.',
  });

  const duplicateRows = duplicateIndexes.map((i) => i + 1);

  return { duplicateDataPoints, duplicateIndexes, duplicateRows, duplicatesErrorMessage };
};

export const checkForInValids = (draft: DraftDataPoint[], intl: IntlShape) => {
  const invalidDataPoints: [DraftDataPoint, number][] = draft.flatMap((dp, index) =>
    dp.value.trim() === '' ? [[dp, index]] : [],
  );

  const invalidValueIndexes = invalidDataPoints.map(([_, index]) => index);

  const invalidValuesErrorMessage = intl.formatMessage({
    description: 'Invalid values error message',
    id: 'mTnjP2',
    defaultMessage: 'All rows must have numerical values.',
  });

  const invalidRows = invalidValueIndexes.map((i) => i + 1);

  return { invalidDataPoints, invalidValueIndexes, invalidRows, invalidValuesErrorMessage };
};
