import { SeriesName } from '../../../../../shared/charts/chart-groups/chart-groups.definitions';
import { ChartView } from '../../../../../shared/charts/chart-toolbox/chart-toolbox.definitions';
import { Series as SeriesSplitChart } from '../../../../../shared/charts/split-chart';

import { SeriesMarkLine } from '../../../../../shared/components/time-series-chart/time-series-chart.definitions';
import { YAxisRange } from '../../../../../shared/components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';

export type SetupTimeSeriesChartVisualizationProps = {
  startTimestamp?: number;
  stopTimestamp: number;
  seriesMarkLines: SeriesMarkLine[];
  chartView: ChartView;
  seriesData?: SeriesSplitChart[];
  yAxisRanges?: Map<SeriesName, YAxisRange>;
  setYAxisRanges?: (ranges: Map<SeriesName, YAxisRange>) => void;
  setChartView: (view: ChartView) => void;
};

export const MAX_GROUP_LENGTH = 8;
