import React, { FunctionComponentElement, useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import EChartsReactCore from 'echarts-for-react/lib/core';
import { NotificationMessage, HORIZON_COLORS as colors } from '@biss/react-horizon-web';
import { useMediaQuery } from 'usehooks-ts';
import cn from 'classnames';

import echartsConfig from '../../common/config/echarts-features';
import { useKeyboard } from '../../common/hooks/use-keyboard';
import useLogger from '../../common/hooks/use-logger/use-logger';
import TrackedEvent from '../../common/tracked-event';
import { isFirefox } from '../../utils';
import PlaceholderChart from '../../charts/placeholder-chart';

import useShouldEnableTouch from './use-should-enable-touch';
import {
  SeriesName,
  SeriesId,
  DataPointObject,
  TimeSeriesChartProps,
  DataZoom,
  LegendSelectedChanged,
  ZoomResetDirection,
} from './time-series-chart.definitions';
import {
  getChartSettings,
  useTooltipFormatter,
  DATA_ZOOM_HEIGHT,
  CHART_TITLE_HEIGHT,
  MAX_SERIES_LENGTH,
  RESET_BUTTON_MARGIN,
  TOOLBOX_HEIGHT,
  LEGEND_TOP,
  GRID_MARGIN,
  CHART_TITLE_MARGIN,
  RESET_BUTTON_LEFT_MARGIN,
  getLegendData,
  encodeSeriesName,
  yAxisDataRangeMin,
  decodeSeriesName,
  formatSeriesName,
  formatLegend,
} from './time-series-chart.helpers';
import TimeSeriesChartInfoBox from './time-series-chart-info-box/time-series-chart-info-box';
import useHorizontalZoom from './use-horizontal-zoom';
import useVerticalZoom from './use-vertical-zoom';
import useResetZoom from './use-reset-zoom/use-reset-zoom';
import useMaxAllowedCanvasSize, { DEFAULT_MAX_CANVAS_SIZE } from './use-max-allowed-canvas-size';
import AxisDetailModal from './axis-detail-modal';
import SideToolbox from './side-toolbox';
import { transformSeriesToSeriesDescriptor } from './axis-detail-modal/axis-detail-modal.helpers';

function TimeSeriesChart({
  series,
  startTime,
  stopTime,
  variant = 'large',
  combinedGraph = false,
  showTooltip = false,
  xAxisFormatter,
  yAxisFormatter,
  useRelativeYAxis,
  seriesMarkLines,
  showToggleSplit,
  toggleSplitView,
  showLegend = false,
  seriesLegend,
  showZoom = false,
  showToolbox = false,
  legendSelected,
  tooltipStyles,
  showSideToolbox = false,
  showDetailModal = false,
  yAxisRanges = new Map(),
  onYAxisRangeChange,
  setYAxisRanges,
  toggleLegendSelected,
  defaultOpenYAxisModal = false,
}: TimeSeriesChartProps): FunctionComponentElement<TimeSeriesChartProps> {
  // reference to the echarts component
  const echartsCore = useRef<EChartsReactCore>(null);

  const [combined, setCombined] = useState(combinedGraph);
  const [toggleSplit, setToggleSplit] = useState(showToggleSplit);

  const seriesTypes: SeriesName[] = Object.keys(series);

  // y-axis ranges modal
  const [openYAxisModal, setOpenYAxisModal] = useState(defaultOpenYAxisModal);

  const zoomX = useHorizontalZoom();
  // the "combined" zoom is used to manage zooming when the chart is combined
  const zoomY = useVerticalZoom([...seriesTypes, 'combined']);

  // attach event listeners
  useEffect(() => {
    // resize the plot whenever the window size changes
    const handler = () => echartsCore.current?.getEchartsInstance().resize();
    window.addEventListener('resize', handler);
    return () => window.removeEventListener('resize', handler);
  }, []);

  const tooltipFormatter = useTooltipFormatter(series, xAxisFormatter);
  const isMobile = useMediaQuery('(max-width: 768px)');
  const shouldEnableTouch = useShouldEnableTouch();
  const keyboard = useKeyboard();
  const logger = useLogger();
  const resetZoom = useResetZoom(zoomX, zoomY);

  // used to warn the user if the canvas element gets too big, and in firefox limit the canvas size
  const { data: maxAllowedCanvasSize = DEFAULT_MAX_CANVAS_SIZE } = useMaxAllowedCanvasSize();

  function enableLegend() {
    if (toggleLegendSelected) {
      toggleLegendSelected({});
    }
  }

  useEffect(() => {
    if (!showToggleSplit) {
      return;
    }
    if (Object.keys(series).length > MAX_SERIES_LENGTH) {
      setToggleSplit(false);
      if (combined) {
        enableLegend();
        setCombined(false);
      }
    } else {
      setToggleSplit(true);
    }
  }, [series, combined, showToggleSplit]);

  const toggleCombinedGraph = () => {
    logger.trackEvent(TrackedEvent.ToggleSplitOrCombinedChart);

    enableLegend();
    setCombined(!combined);
    resetZoom(ZoomResetDirection.Y);
    if (toggleSplitView) {
      toggleSplitView(!combined);
    }
  };

  if (!seriesTypes.length) {
    return <PlaceholderChart />;
  }
  const chartVariant = isMobile ? 'small' : variant;
  const settings = getChartSettings(chartVariant, combined, showZoom, showLegend);

  const dataset: {
    dimensions: ['ts', 'v'];
    source: DataPointObject[];
    dataTrackId: SeriesId;
  }[] = seriesTypes.flatMap((type) =>
    series[type].map((dataTrack) => ({
      dimensions: ['ts', 'v'],
      source: dataTrack.dataPoints,
      dataTrackId: dataTrack.dataTrackId,
    })),
  );

  const yAxisSettings = () =>
    seriesTypes.map((type, index) => {
      const range: Record<'min' | 'max', null | number | Function> = {
        // calculate the min of the axis to be closer to the actual minimum of the data tracks values
        min: useRelativeYAxis ? yAxisDataRangeMin : null,
        max: null,
      };

      // override the min and max values by user input
      const maybeYAxisRange = yAxisRanges.get(type);
      if (maybeYAxisRange) {
        range.min = maybeYAxisRange.min ?? range.min;
        range.max = maybeYAxisRange.max ?? null;
      }

      // TODO (BIOCL-4775): handle gracefully when bug is fixed
      // this is a workaround to prevent the bug to break the application when the series is not available
      let axisName = `axis-${index}`;
      const firstSeries = series[type].at(0);

      if (firstSeries !== undefined && firstSeries.engineeringUnit !== undefined) {
        axisName = firstSeries.engineeringUnit;
      } else {
        logger.error(
          `Bug BIOCL-4775: A data track was selected that does not contain an engineering unit. Selected type: ${type}, series: ${series[type]}, first series: ${firstSeries}, engineeringUnit: ${firstSeries?.engineeringUnit}`,
        );
      }

      return {
        type: 'value',
        name: axisName,
        position: 'left',
        gridIndex: combined ? 0 : index,
        alignTicks: true,
        fontSize: settings.fontSize,
        triggerEvent: true,
        axisLine: {
          show: true,
        },
        axisLabel: {
          formatter: yAxisFormatter
            ? (value: number) => yAxisFormatter(value, series[type].at(0)?.fractionalDigits)
            : undefined,
          fontSize: settings.fontSize,
        },
        nameTextStyle: {
          fontSize: settings.fontSize,
          lineHeight: settings.lineHeight,
        },
        offset: combined ? index * settings.yAxisOffset : 0,
        ...range,
      };
    });

  const xAxisSettings = () => {
    if (combined) {
      return {
        gridIndex: 0,
        type: 'time',
        min: startTime,
        max: stopTime,
        axisLabel: {
          rotate: 45,
          fontSize: settings.fontSize,
          formatter: xAxisFormatter,
        },
      };
    }

    return seriesTypes.map((_type, index) => ({
      gridIndex: index,
      type: 'time',
      min: startTime,
      max: stopTime,
      axisLabel: {
        rotate: 45,
        fontSize: settings.fontSize,
        formatter: xAxisFormatter,
      },
    }));
  };

  const getMarkLines = () => {
    if (!seriesMarkLines) {
      return {};
    }
    return {
      symbol: ['none', 'none'],
      silent: true,
      data: seriesMarkLines.map((markLine) => ({
        name: markLine.name,
        xAxis: markLine.timestamp,
        label: {
          show: true,
          formatter: markLine.name,
          position: 'insideStartBottom',
          color: markLine.color,
          fontSize: settings.markLine.fontSize,
        },
        itemStyle: {
          color: markLine.color,
        },
        lineStyle: {
          color: markLine.color,
        },
      })),
    };
  };

  const getSeries = () =>
    seriesTypes.flatMap((type, gridIndex) =>
      series[type].map((item) => ({
        type: 'line',
        lineStyle: {
          type: item.lineType || 'line',
          width: item.width || settings.lineStyle.width,
        },
        xAxisIndex: combined ? 0 : gridIndex,
        yAxisIndex: gridIndex,
        name: encodeSeriesName(
          item.dataTrackType,
          item.engineeringUnit,
          item.processRecord?.displayName,
          item.processRecord?.unit,
        ),
        datasetIndex: dataset.findIndex((entry) => entry.dataTrackId === item.dataTrackId),
        animation: false,
        showSymbol: false,
        color: item.color,
        markLine: getMarkLines(),
      })),
    );

  const getGrid = () => {
    if (combined) {
      const toolboxHeight = showToolbox ? TOOLBOX_HEIGHT : 0;
      const topMargin = settings.legend.height + toolboxHeight;
      return {
        ...settings.grid,
        left: seriesTypes.length * settings.yAxisOffset,
        top: topMargin || GRID_MARGIN,
      };
    }
    return seriesTypes.map((_type, index) => ({
      ...settings.grid,
      top: `${settings.chartHeight * index + CHART_TITLE_HEIGHT}px`,
    }));
  };

  const getDataZoom = () => {
    if (!showZoom) {
      return null;
    }

    return seriesTypes.flatMap((track, index) => [
      {
        realtime: true,
        id: `xs${index}`,
        type: 'slider',
        start: zoomX.current.start,
        end: zoomX.current.end,
        orient: 'horizontal',
        // slider controls all x axis at the same time -> all charts zoomed simultaneously
        xAxisIndex: [0, index],
        filterMode: 'none',
        left: combined ? settings.yAxisOffset * seriesTypes.length : settings.dataZoom.left,
        right: 20,
        showDataShadow: false,
        height: DATA_ZOOM_HEIGHT,
        ...(combined
          ? { bottom: 10 }
          : {
              top:
                settings.chartHeight -
                settings.dataZoom.height -
                CHART_TITLE_MARGIN +
                settings.chartHeight * index,
            }),
        zoomLock: false,
        labelFormatter: xAxisFormatter,
      },
      {
        type: 'inside',
        id: `xi${index}`,
        start: zoomX.current.start,
        end: zoomX.current.end,
        orient: 'horizontal',
        xAxisIndex: [0, index],
        rangeMode: ['value', 'percent'],
        zoomLock: !(shouldEnableTouch || keyboard.Shift.held || keyboard.Control.held),
        zoomOnMouseWheel: 'shift',
        moveOnMouseWheel: 'ctrl',
        moveOnMouseMove: true,
        preventDefaultMouseMove: false,
        filterMode: 'none',
        labelFormatter: xAxisFormatter,
      },
      {
        type: 'slider',
        id: `y${track}`,
        start: combined
          ? zoomY.current.combined?.start
          : (zoomY.current[track]?.start ?? { [track]: { start: 0, end: 100 }, ...zoomY.current }),
        end: combined
          ? zoomY.current.combined?.end
          : (zoomY.current[track]?.end ?? { [track]: { start: 0, end: 100 }, ...zoomY.current }),
        rangeMode: ['percent', 'percent'],
        yAxisIndex: combined ? [0, index] : index,
        orient: 'vertical',
        filterMode: 'none',
        show: true,
        showDataShadow: false,
        brushSelect: false,
        showDetail: !combined,
      },
    ]);
  };

  const getToolbox = () => {
    if (!showToolbox) {
      return null;
    }

    return {
      show: true,
      itemGap: settings.toolbox.itemGap,
      top: 0,
      feature: {
        myToggleSplit: {
          show: toggleSplit,
          title: 'Split/Combine the Graphs',
          icon: combined
            ? 'path://M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9'
            : 'path://M8.25 5.25L12 9 15.75 5.25m0 13.5L12 15 8.25 18.75',
          onclick: toggleCombinedGraph,
        },
        dataView: {
          readOnly: false,
          buttonColor: colors.blue.DEFAULT,
          buttonTextColor: '#FFFFFF',
        },
        saveAsImage: {
          name: 'BioNsight',
          pixelRatio: 2,
          onclick: () => {
            logger.trackEvent(TrackedEvent.ExportGraph);
          },
        },
      },
    };
  };

  const getTooltip = () => ({
    trigger: 'axis',
    show: showTooltip,
    order: 'seriesDesc',
    formatter: tooltipFormatter,
    ...tooltipStyles,
  });

  function getResetZoomButton(top: number, left: number) {
    return {
      type: 'image',
      style: {
        image: '/assets/icons/resetZoom.png',
        x: left,
        y: top,
        width: 20,
        height: 20,
      },
      onclick() {
        resetZoom();
      },
    };
  }

  function getLegend() {
    if (showLegend === false) {
      return null;
    }

    // mixed legend with data track types
    if (combined) {
      return {
        show: true,
        top: showToolbox ? LEGEND_TOP : 0,
        data: getLegendData(seriesLegend),
        selected: legendSelected,
        formatter: (name: string) => {
          const { engineeringUnit, seriesType } = decodeSeriesName(name);
          return formatSeriesName(seriesType, engineeringUnit);
        },
      };
    }

    // do not show legends in split view if only one process record is selected
    if (seriesTypes.every((type) => series[type].length <= 1)) {
      return null;
    }

    // legends in split view with process record name and units
    return seriesTypes.map((type, index) => ({
      type: 'scroll',
      show: true,
      width: '64%',
      top: index * settings.chartHeight,
      data: series[type].map((dataTrack) => ({
        name: encodeSeriesName(
          dataTrack.dataTrackType,
          dataTrack.engineeringUnit,
          dataTrack.processRecord?.displayName,
          dataTrack.processRecord?.unit,
        ),
        itemStyle: {
          color: dataTrack.color,
        },
        lineStyle: {
          color: dataTrack.color,
        },
      })),

      formatter: (name: string) => {
        const { processRecordDisplayName, processRecordUnit, engineeringUnit, seriesType } =
          decodeSeriesName(name);

        if (processRecordDisplayName && processRecordUnit) {
          return formatLegend(processRecordDisplayName, processRecordUnit);
        }

        return formatSeriesName(seriesType, engineeringUnit);
      },
    }));
  }

  function getGraphic() {
    if (!showZoom) {
      return null;
    }

    const chartHeightFirst = settings.chartHeight - settings.dataZoom.height + RESET_BUTTON_MARGIN;
    const left =
      (combined ? settings.yAxisOffset * seriesTypes.length : settings.dataZoom.left) -
      RESET_BUTTON_LEFT_MARGIN;

    return combined
      ? [getResetZoomButton(settings.legend.height + chartHeightFirst, left)]
      : seriesTypes.map((_, index) =>
          getResetZoomButton(chartHeightFirst + index * settings.chartHeight, left),
        );
  }

  // set the clicked series type as selected
  const handleYAxisClick = (params: object) => {
    if ('yAxisIndex' in params === false || typeof params.yAxisIndex !== 'number') {
      return;
    }

    setOpenYAxisModal(true);
  };

  const onLegendSelectedChanged = ({ selected }: LegendSelectedChanged) => {
    if (toggleLegendSelected) {
      toggleLegendSelected(selected);
    }
  };

  const onDataZoomChanged = ({ start, end, batch, dataZoomId }: DataZoom) => {
    if (start !== undefined && end !== undefined && dataZoomId !== undefined) {
      // vertical zoom
      if (dataZoomId[0] === 'y') {
        if (combined) {
          // in a combined graph, all y-Zoom scales have to be set to the same %-values, otherwise they will jump out of sync on redraw.
          zoomY.current = Object.fromEntries(
            Object.keys(zoomY.current).map((key) => [key, { start, end }]),
          );
        } else {
          zoomY.current = { ...zoomY.current, [dataZoomId.slice(1)]: { start, end } };
        }
        return;
      }

      // horizontal zoom
      zoomX.current = { start, end };
      return;
    }

    if (batch) {
      const last = batch[batch.length - 1];
      zoomX.current = {
        start: last.start ?? zoomX.current.start,
        end: last.end ?? zoomX.current.end,
      };
    }
  };

  const chartHeight = combined ? settings.height : settings.chartHeight * seriesTypes.length;

  return (
    <>
      {showDetailModal && (
        <AxisDetailModal
          open={openYAxisModal}
          onOpenChange={(isOpen) => setOpenYAxisModal(isOpen)}
          ranges={yAxisRanges}
          series={transformSeriesToSeriesDescriptor(series)}
          onRangeChange={setYAxisRanges}
        />
      )}

      <div className="flex grid-cols-2 flex-col gap-4 px-4">
        {chartHeight > maxAllowedCanvasSize.height && (
          <NotificationMessage status="warning">
            <FormattedMessage
              description="Maximum Number Of Data Tracks Selected Message"
              defaultMessage="The chart has reached its maximum height, please adjust the number of selected Data Tracks."
              id="eTRA0M"
            />

            {isFirefox() && (
              <FormattedMessage
                description="Data Tracks Hidden"
                defaultMessage=" Some Data Tracks could not be visualized."
                id="ZG1FLm"
              />
            )}
          </NotificationMessage>
        )}

        {showToggleSplit && (
          <TimeSeriesChartInfoBox seriesLength={seriesTypes.length} combined={combined} />
        )}
      </div>
      <div
        className={cn({
          'flex flex-col md:grid md:grid-cols-[6rem,1fr]': combined === false && showSideToolbox,
        })}
        style={{
          gridTemplateRows: `repeat(${seriesTypes.length},1fr)`,
        }}
      >
        {combined === false &&
          showSideToolbox &&
          seriesTypes.map((seriesType) => (
            <SideToolbox
              seriesName={seriesType}
              range={yAxisRanges.get(seriesType) ?? {}}
              onRangeChange={onYAxisRangeChange}
            />
          ))}

        <EChartsReactCore
          ref={echartsCore}
          echarts={echartsConfig}
          className="col-start-2 col-end-2 row-start-1 row-end-[-1]"
          style={{
            height: `${chartHeight}px`,
            // limit the canvas height on firefox so as to not risk crashing the browser
            ...(isFirefox() && { maxHeight: `${maxAllowedCanvasSize.height}px` }),
          }}
          onEvents={{
            datazoom: onDataZoomChanged,
            legendselectchanged: onLegendSelectedChanged,
            click: handleYAxisClick,
          }}
          option={{
            dataset,
            title: seriesTypes?.map((type, index) => ({
              text: type,
              show: !combined,
              top: `${settings.chartHeight * index}px`,
              left: 'center',
              textStyle: {
                lineHeight: CHART_TITLE_HEIGHT,
              },
            })),
            grid: getGrid(),
            xAxis: xAxisSettings(),
            yAxis: yAxisSettings(),
            series: getSeries(),
            tooltip: getTooltip(),
            graphic: getGraphic(),
            legend: getLegend(),
            animation: false,
            dataZoom: getDataZoom(),
            toolbox: getToolbox(),
            axisPointer: {
              link: [
                {
                  xAxisIndex: 'all',
                },
              ],
            },
          }}
          notMerge
          lazyUpdate
          theme="horizon-web"
        />
      </div>
    </>
  );
}

export default TimeSeriesChart;
