import {
  ProcessRecordEventEventType,
  ProcessRecordEventLevel,
} from '../../common/types/generated/prs.generated';
import { Timestamp } from '../../common/types/timestamp';
import { Uuid } from '../../common/types/uuid';

export const MANY_EVENTS_THRESHOLD = 500;

export type EventListItem = {
  /** Unique identifier. */
  id: Uuid;
  /** @example "Single" */
  eventType: ProcessRecordEventEventType;
  /** @example "Using initial temperature compensation source: Individual" */
  message: string;
  /**
   * Timestamp in UTC when event was recorded and stored in data services on DWC.
   * Timestamps have to be UTC and in ISO8601 format ('YYYY-MM-DDThh:mm:ss.sssZ').
   */
  timestamp: Timestamp;
  /**
   * Contains information about system, plant, workflow and unit if available.
   * @example "\\\\CTPC06108\\DASGIP Plant DASbox-8\\Administrator 3e464510\\DO Calibration"
   */
  logicalReferenceComment: string;
  /** @example "\\\\CTPC06863\\COM3\\PH4PO4RD (5)\\DO2" */
  physicalReferenceComment: string;
  /** @example "Info" */
  level: ProcessRecordEventLevel;
  /** @example "Example application name" */
  applicationName?: string;
  /** @example "Example application version" */
  applicationVersion?: string;
  /** @example "DASware core" */
  actorName: string;
};

interface LoadingEvents {
  state: 'loading';
}

interface ErrorLoadingEvents {
  state: 'error';
}

interface SuccessfulLoadingEvents {
  state: 'success';
  events: EventListItem[];
}

export type EventState = LoadingEvents | ErrorLoadingEvents | SuccessfulLoadingEvents;

export type EventListProps = {
  /** Contains the current state of fetching data. When data was successfully received it additionally contains the data. */
  data: EventState;

  /** Allows to show events by default, even when many events are present. */
  defaultOverrideShowingEvents?: boolean;

  // Optional sticky footer to be displayed at the bottom of the list.
  footer?: React.ReactNode;
};
