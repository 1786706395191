import React, { ReactNode } from 'react';
import { IntlShape, defineMessages } from 'react-intl';
import { VesselIcon, ChartsIcon, UserIcon } from '@biss/react-horizon-web';

export type Step = {
  title: string;
  description?: string;
  image?: string;
  imageDesc?: string;
};

export type Section = {
  title: string;
  icon: ReactNode;
  subsections: SubSection[];
};

export type SubSection = {
  title: string;
  link?: string;
  steps?: Step[] | ((intl: IntlShape) => Step[]);
};

export const WELCOME_DWC_SETUP_MESSAGES = defineMessages({
  step1Title: {
    id: 'D+w7MI',
    defaultMessage: 'Go to your DASware control',
    description: 'Welcome:Steps:dwcSetup Step 1 Title',
  },
  step1Desc: {
    id: 'NDIMV6',
    defaultMessage:
      'Go to the DASware control that you want to upload the data to the cloud from. Please note that this feature requires DASware control version 6.5 or higher. Furthermore, you need to activate the user license for the cloud and you need to have an account in the BioNsight cloud.',
    description: 'Welcome:Steps:dwcSetup Step 1 Description',
  },
  step2Title: {
    id: '8m/jat',
    defaultMessage: 'Go to the options screen',
    description: 'Welcome:Steps:dwcSetup Step 2 Title',
  },
  step2Desc: {
    id: 'Po1f5q',
    defaultMessage: 'First, select "tools", then "options".',
    description: 'Welcome:Steps:dwcSetup Step 2 Description',
  },
  step2ImgDesc: {
    id: '6fTera',
    defaultMessage: 'The options screen in DASware control showing the cloud section.',
    description: 'Welcome:Steps:dwcSetup Step 2 Image Description',
  },
  step3Title: {
    id: 'tG7A/l',
    defaultMessage: 'Enter the cloud connection URL',
    description: 'Welcome:Steps:dwcSetup Step 3 Title',
  },
  step3Desc: {
    id: 'LFJ5uY',
    defaultMessage:
      'Enter the following URL in the "Cloud"-section of the options https://api.cloud.bionsight.eppendorf.com/lab-connectivity-service',
    description: 'Welcome:Steps:dwcSetup Step 3 Description',
  },
  step3ImgDesc: {
    id: 'tB1na4',
    defaultMessage: 'The options screen in DASware control showing the cloud section.',
    description: 'Welcome:Steps:dwcSetup Step 3 Image Description',
  },
  step4Title: {
    id: 'oBoCHO',
    defaultMessage: 'Save & Close in order to update URL in the software before logging in',
    description: 'Welcome:Steps:dwcSetup Step 4 Title',
  },
  step4Desc: {
    id: 'NmpfCA',
    defaultMessage:
      'In order for DASware control to use the new URL, Save & Close the options before logging in.',
    description: 'Welcome:Steps:dwcSetup Step 4 Description',
  },
  step5Title: {
    id: 'T83cdi',
    defaultMessage: 'Open the "Options" dialog again and press "Log in"',
    description: 'Welcome:Steps:dwcSetup Step 5 Title',
  },
  step5Desc: {
    id: '15N4bE',
    defaultMessage: 'This will open a separate window where you log in to BioNsight cloud.',
    description: 'Welcome:Steps:dwcSetup Step 5 Description',
  },
  step6Title: {
    id: 'QPhqhN',
    defaultMessage: 'Enter user credentials',
    description: 'Welcome:Steps:dwcSetup Step 6 Title',
  },
  step6Desc: {
    id: 'A42Imv',
    defaultMessage: 'Enter your username and password from your BioNsight cloud account.',
    description: 'Welcome:Steps:dwcSetup Step 3 Description',
  },
  step6ImgDesc: {
    id: 'J0AHJf',
    defaultMessage: 'The BioNsight cloud login form',
    description: 'Welcome:Steps:dwcSetup Step 6 Image Description',
  },
  step7Title: {
    id: '/EvbXy',
    defaultMessage: 'Confirm that your user name appears under "Cloud connection user"',
    description: 'Welcome:Steps:dwcSetup Step 7 Title',
  },
  step7Desc: {
    id: 'PcdcJ2',
    defaultMessage:
      'You can verify if your user-credentials were successfully stored in the "Cloud"-section of the options under "Cloud connection user".',
    description: 'Welcome:Steps:dwcSetup Step 7 Description',
  },
  step8Title: {
    id: 'BnwJuX',
    defaultMessage: 'Activate automatic upload to cloud in order to see workflows in Monitoring',
    description: 'Welcome:Steps:dwcSetup Step 8 Title',
  },
  step8Desc: {
    id: '2+pArt',
    defaultMessage:
      'If you want new completed workflows to show up in the monitoring overview automatically, activate the option "Automatic upload to cloud"',
    description: 'Welcome:Steps:dwcSetup Step 8 Description',
  },
  step9Title: {
    id: '+6/S/3',
    defaultMessage: 'Press "Save & Close"',
    description: 'Welcome:Steps:dwcSetup Step 9 Title',
  },
  step9Desc: {
    id: 'iie5xq',
    defaultMessage: 'Save your settings and close the options window.',
    description: 'Welcome:Steps:dwcSetup Step 9 Description',
  },
  step10Title: {
    id: 'V60G1t',
    defaultMessage: 'Confirm that event log shows message "Connection to Cloud: Connected"',
    description: 'Welcome:Steps:dwcSetup Step 10 Title',
  },
  step10Desc: {
    id: 'mjMpHf',
    defaultMessage:
      'Check for the message "Connection to Cloud: Connected" in the event log that indicates a successful communication between DASware control and the BioNsight cloud.',
    description: 'Welcome:Steps:dwcSetup Step 10 Description',
  },
  step11Title: {
    id: 'B8tZ7Q',
    defaultMessage: 'Confirm (after 1-2 min) that the cloud icon shows successful connection state',
    description: 'Welcome:Steps:dwcSetup Step 11 Title',
  },
  step11Desc: {
    id: 'wJvO6E',
    defaultMessage:
      'You can monitor the connection state between DASware control and BioNsight cloud using the small cloud icon.',
    description: 'Welcome:Steps:dwcSetup Step 11 Description',
  },
  step11ImgDesc: {
    id: 'm8ydDC',
    defaultMessage:
      'The cloud-icon in the DWC user-interface indicating that it connected successfully to the cloud.',
    description: 'Welcome:Steps:dwcSetup Step 11 Image Description',
  },
});

export const welcomeGetDWCSetupSteps = (intl: IntlShape): Step[] => [
  {
    title: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step1Title),
    description: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step1Desc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step2Title),
    description: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step2Desc),
    image: '/assets/images/dwc-setup/dwc_setup_1.jpg',
    imageDesc: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step2ImgDesc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step3Title),
    description: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step3Desc),
    image: '/assets/images/dwc-setup/dwc_setup_2.jpg',
    imageDesc: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step3ImgDesc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step4Title),
    description: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step4Desc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step5Title),
    description: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step5Desc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step6Title),
    description: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step6Desc),
    image: '/assets/images/dwc-setup/dwc_setup_3.jpg',
    imageDesc: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step6ImgDesc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step7Title),
    description: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step7Desc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step8Title),
    description: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step8Desc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step9Title),
    description: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step9Desc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step10Title),
    description: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step10Desc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step11Title),
    description: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step11Desc),
    image: '/assets/images/dwc-setup/dwc_setup_4.jpg',
    imageDesc: intl.formatMessage(WELCOME_DWC_SETUP_MESSAGES.step11ImgDesc),
  },
];

export const WELCOME_MONITORING_CONFIGURATION_STEPS = defineMessages({
  step1Title: {
    description: 'Welcome:Steps:MonitoringConfiguration: Step 1 title',
    defaultMessage: 'Click "Configure Overview"',
    id: 'rjQ+z2',
  },
  step1Desc: {
    description: 'Welcome:Steps:MonitoringConfiguration: Step 1 description',
    defaultMessage:
      'When the configuration panel is open, you have the option to set a time span, select data tracks and set y-axes ranges.',
    id: 'YagrON',
  },
  step1ImgDesc: {
    defaultMessage:
      'The configuration panel on monitoring overview to configure time span, select data tracks and set y-axes ranges.',
    description: 'Welcome:Steps:MonitoringConfiguration: Step 1 image description',
    id: '+HzDbz',
  },
  step2Title: {
    defaultMessage: 'Set the Time Span',
    description: 'Welcome:Steps:MonitoringConfiguration: Step 2 title',
    id: 'U4kcDO',
  },
  step2Desc: {
    defaultMessage:
      'Select between the last 1h, 6h, 12h or 24h as the time span for all the graphs in the Monitoring Overview.',
    id: '1b0/v0',
    description: 'Welcome:Steps:MonitoringConfiguration: Step 2 description',
  },
  step3Title: {
    defaultMessage: 'Add and Select Data Tracks',
    description: 'Welcome:Steps:MonitoringConfiguration: Step 3 title',
    id: 'qT9eMc',
  },
  step3Desc: {
    defaultMessage:
      'Select between the last 1h, 6h, 12h or 24h as the time span for all the graphs in the Monitoring Overview.',
    id: 'o8gKlF',
    description: 'Welcome:Steps:MonitoringConfiguration: Step 3 description',
  },
  step4Title: {
    defaultMessage: 'Set Y-Axes Ranges',
    description: 'Welcome:Steps:MonitoringConfiguration: Step 4 title',
    id: '1VtrB1',
  },
  step4Desc: {
    defaultMessage:
      'Set a y-axis range for each of the selected data tracks, which will be applied to all the graphs in the Monitoring Overview.',
    id: 'UZz1D2',
    description: 'Welcome:Steps:MonitoringConfiguration: Step 4 description',
  },
});

export const welcomeMonitoringConfigurationSteps = (intl: IntlShape): Step[] => [
  {
    title: intl.formatMessage(WELCOME_MONITORING_CONFIGURATION_STEPS.step1Title),
    image: '/assets/images/monitoring-configuration-steps/configure_overview.png',
    imageDesc: intl.formatMessage(WELCOME_MONITORING_CONFIGURATION_STEPS.step1ImgDesc),
    description: intl.formatMessage(WELCOME_MONITORING_CONFIGURATION_STEPS.step1Desc),
  },
  {
    title: intl.formatMessage(WELCOME_MONITORING_CONFIGURATION_STEPS.step2Title),
    description: intl.formatMessage(WELCOME_MONITORING_CONFIGURATION_STEPS.step2Desc),
  },
  {
    title: intl.formatMessage(WELCOME_MONITORING_CONFIGURATION_STEPS.step3Title),
    description: intl.formatMessage(WELCOME_MONITORING_CONFIGURATION_STEPS.step3Desc),
  },
  {
    title: intl.formatMessage(WELCOME_MONITORING_CONFIGURATION_STEPS.step4Title),
    description: intl.formatMessage(WELCOME_MONITORING_CONFIGURATION_STEPS.step4Desc),
  },
];

export const WELCOME_MONITORING_COMPARE_CURRENT_RUN_STEPS = defineMessages({
  step1Title: {
    description: 'Welcome:Steps:CompareCurrentRun: Step 1 title',
    defaultMessage: 'Select a Unit',
    id: 'ZfM4RG',
  },
  step1Desc: {
    description: 'Welcome:Steps:CompareCurrentRun: Step 2 description',
    defaultMessage:
      'Go to the Unit Detail View of the current run you want to compare to a reference run.',
    id: 'LPQ5eX',
  },
  step2Title: {
    defaultMessage: 'Add a Reference',
    description: 'Welcome:Steps:CompareCurrentRun: Step 2 title',
    id: 'kDMoeM',
  },
  step2Desc: {
    defaultMessage:
      'Click "Add Reference" and select the process record you want to compare your current run to.',
    id: 'jQELTZ',
    description: 'Welcome:Steps:CompareCurrentRun: Step 2 description',
  },
  step2ImgDesc: {
    defaultMessage: 'Adding Reference curve to compare the current run',
    id: 't22c9R',
    description: 'Welcome:Steps:CompareCurrentRun: Step 2 image description',
  },
  step3Title: {
    defaultMessage: 'Remove the Reference',
    description: 'Welcome:Steps:CompareCurrentRun: Step 3 title',
    id: '/ryLdt',
  },
  step3Desc: {
    defaultMessage:
      'In case you want to remove the reference again, click on the trash icon next to it.',
    id: 'K7XvKU',
    description: 'Welcome:Steps:CompareCurrentRun: Step 3 description',
  },
});

export const welcomeCompareCurrentRunsSteps = (intl: IntlShape): Step[] => [
  {
    title: intl.formatMessage(WELCOME_MONITORING_COMPARE_CURRENT_RUN_STEPS.step1Title),
    description: intl.formatMessage(WELCOME_MONITORING_COMPARE_CURRENT_RUN_STEPS.step1Desc),
  },
  {
    title: intl.formatMessage(WELCOME_MONITORING_COMPARE_CURRENT_RUN_STEPS.step2Title),
    image: '/assets/images/monitoring-configuration-steps/select_reference.png',
    imageDesc: intl.formatMessage(WELCOME_MONITORING_COMPARE_CURRENT_RUN_STEPS.step2ImgDesc),
    description: intl.formatMessage(WELCOME_MONITORING_COMPARE_CURRENT_RUN_STEPS.step2Desc),
  },
  {
    title: intl.formatMessage(WELCOME_MONITORING_COMPARE_CURRENT_RUN_STEPS.step3Title),
    description: intl.formatMessage(WELCOME_MONITORING_COMPARE_CURRENT_RUN_STEPS.step3Desc),
  },
];

export const WELCOME_ADD_DATA_TO_CURRENT_RUN_MONITORING_STEPS = defineMessages({
  step1Title: {
    description: 'Welcome:Steps:MonitoringAddDataToCurrentRun: Step 1 title',
    defaultMessage: 'Select a Unit',
    id: 'H1O5fc',
  },
  step1Desc: {
    description: 'Welcome:Steps:MonitoringAddDataToCurrentRun: Step 1 description',
    defaultMessage: 'Go to the Unit Detail View of the current run you want to add data to.',
    id: 'RR13KB',
  },
  step2Title: {
    defaultMessage: 'Create Data Tracks',
    description: 'Welcome:Steps:MonitoringAddDataToCurrentRun: Step 2 title',
    id: 'i0Q5gK',
  },
  step2Desc: {
    defaultMessage:
      'Click the plus icon next to the search field of the data track list. Define the names and engineering units of the data tracks you want to create.',
    id: 'bO495r',
    description: 'Welcome:Steps:MonitoringAddDataToCurrentRun: Step 2 description',
  },
  step2ImgDesc: {
    defaultMessage: 'Add new custom data track',
    id: 'rK8gZW',
    description: 'Welcome:Steps:MonitoringAddDataToCurrentRun: Step 2 image description',
  },
  step3Title: {
    defaultMessage: 'Add Data Points',
    description: 'Welcome:Steps:MonitoringAddDataToCurrentRun: Step 3 title',
    id: 'L04uh8',
  },
  step3Desc: {
    defaultMessage:
      'Find the newly created data track in the data track list and click the pen icon next to it. Now you can sequentially add and save data points to this data track.',
    id: 'zM5r/T',
    description: 'Welcome:Steps:MonitoringAddDataToCurrentRun: Step 3 description',
  },
  step3ImgDesc: {
    defaultMessage: 'Add or delete custom data points',
    id: 'd8SxP0',
    description: 'Welcome:Steps:MonitoringAddDataToCurrentRun: Step 3 image description',
  },
  step4Title: {
    defaultMessage: 'Remove Data Points',
    description: 'Welcome:Steps:MonitoringAddDataToCurrentRun: Step 4 title',
    id: 'APBNzY',
  },
  step4Desc: {
    defaultMessage:
      'In case you want to remove a formally saved data point, click first on its row and then on the appearing the trash icon.',
    id: 'r5yDkF',
    description: 'Welcome:Steps:MonitoringAddDataToCurrentRun: Step 4 dewcription',
  },
  step4ImgDesc: {
    defaultMessage: 'Delete custom data points',
    id: 'Itj58l',
    description: 'Welcome:Steps:MonitoringAddDataToCurrentRun: Step 4 image description',
  },
});

export const welcomeAddDataToCurrentRunMonitoringSteps = (intl: IntlShape): Step[] => [
  {
    title: intl.formatMessage(WELCOME_ADD_DATA_TO_CURRENT_RUN_MONITORING_STEPS.step1Title),
    description: intl.formatMessage(WELCOME_ADD_DATA_TO_CURRENT_RUN_MONITORING_STEPS.step1Desc),
  },
  {
    title: intl.formatMessage(WELCOME_ADD_DATA_TO_CURRENT_RUN_MONITORING_STEPS.step2Title),
    image: '/assets/images/monitoring-configuration-steps/add_data_track.png',
    imageDesc: intl.formatMessage(WELCOME_ADD_DATA_TO_CURRENT_RUN_MONITORING_STEPS.step2ImgDesc),
    description: intl.formatMessage(WELCOME_ADD_DATA_TO_CURRENT_RUN_MONITORING_STEPS.step2Desc),
  },
  {
    title: intl.formatMessage(WELCOME_ADD_DATA_TO_CURRENT_RUN_MONITORING_STEPS.step3Title),
    image: '/assets/images/monitoring-configuration-steps/edit_data_points.png',
    imageDesc: intl.formatMessage(WELCOME_ADD_DATA_TO_CURRENT_RUN_MONITORING_STEPS.step3ImgDesc),
    description: intl.formatMessage(WELCOME_ADD_DATA_TO_CURRENT_RUN_MONITORING_STEPS.step3Desc),
  },
  {
    title: intl.formatMessage(WELCOME_ADD_DATA_TO_CURRENT_RUN_MONITORING_STEPS.step4Title),
    image: '/assets/images/monitoring-configuration-steps/delete_data_points.png',
    imageDesc: intl.formatMessage(WELCOME_ADD_DATA_TO_CURRENT_RUN_MONITORING_STEPS.step4ImgDesc),
    description: intl.formatMessage(WELCOME_ADD_DATA_TO_CURRENT_RUN_MONITORING_STEPS.step4Desc),
  },
];

export const WELCOME_DWC_UPLOAD_MESSAGES = defineMessages({
  step1Title: {
    id: '/qHx+B',
    defaultMessage: 'Select a workflow in DASware control.',
    description: 'Welcome:Steps:dwcUpload Step 1 Title',
  },
  step1Desc: {
    id: 'MlYn7E',
    defaultMessage: 'Select one of the finished workflows in the DASware control list.',
    description: 'Welcome:Steps:dwcUpload Step 1 Description',
  },
  step2Title: {
    id: 'Fn1wXE',
    defaultMessage: 'Right-click on the workflow you want to upload to the cloud.',
    description: 'Welcome:Steps:dwcUpload Step 2 Title',
  },
  step2Desc: {
    id: '/6j5PI',
    defaultMessage: 'The context-menu gives you a number of options to export your workflow.',
    description: 'Welcome:Steps:dwcUpload Step 2 Description',
  },
  step2ImgDesc: {
    id: 'EdAe0R',
    defaultMessage:
      'The upload to cloud option in the context menu of a workflow in DASware control.',
    description: 'Welcome:Steps:dwcUpload Step 2 Image Description',
  },
  step2bImgDesc: {
    id: 'q/djDz',
    defaultMessage:
      'The export to file option in the context menu of a workflow in DASware control.',
    description: 'Welcome:Steps:dwcUpload Step 2b Image Description',
  },
  step3Title: {
    id: 'vFTenc',
    defaultMessage: 'Click on Upload To Cloud',
    description: 'Welcome:Steps:dwcUpload Step 3 Title',
  },
  step3bTitle: {
    id: 'oEmm+8',
    defaultMessage: 'Click on Export',
    description: 'Welcome:Steps:dwcUpload Step 3b Title',
  },
  step3Desc: {
    id: 'oijIr1',
    defaultMessage:
      'This will upload the workflow to the cloud where you can find it in the list of process records of the Analytics section.',
    description: 'Welcome:Steps:dwcUpload Step 3 Description',
  },
  step3bDesc: {
    id: 'w3UgxF',
    defaultMessage: 'This will export your workflow as a CSV-file.',
    description: 'Welcome:Steps:dwcUpload Step 3b Description',
  },
  step4Title: {
    id: 'qZko1c',
    defaultMessage: 'Upload the file in Analytics.',
    description: 'Welcome:Steps:dwcUpload Step 4 Title',
  },
  step4Desc: {
    id: 'TR7+45',
    defaultMessage:
      'Go to the process record list in the Analytics module, click on Create New Process Record, and upload the exported file there.',
    description: 'Welcome:Steps:dwcUpload Step 4 Description',
  },
});

export const welcomeGetDWCUploadSteps = (intl: IntlShape): Step[] => [
  {
    title: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step1Title),
    description: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step1Desc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step2Title),
    description: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step2Desc),
    image: '/assets/images/dwc-setup/dwc_upload.png',
    imageDesc: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step2ImgDesc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step3Title),
    description: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step3Desc),
  },
];

export const welcomeGetFileUploadSteps = (intl: IntlShape): Step[] => [
  {
    title: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step1Title),
    description: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step1Desc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step2Title),
    description: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step2Desc),
    image: '/assets/images/dwc-setup/dwc_export_2.png',
    imageDesc: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step2bImgDesc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step3bTitle),
    description: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step3bDesc),
  },
  {
    title: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step4Title),
    description: intl.formatMessage(WELCOME_DWC_UPLOAD_MESSAGES.step4Desc),
  },
];

export const WELCOME_CREATE_PROCESS_RECORD_STEPS: Step[] = [
  {
    title: 'Go to the list of process records',
    description:
      'Go to the process record list in the Analytics module, click on Create New Process Record, and select the option to create a process record without a file.',
  },
  {
    title: 'Enter the required information',
    description:
      'For the creation of a new process record, we need some basic information such as a name for the process record, the name of the unit the process was run on, a start and an end date of the process.',
  },
  {
    title: 'Create the new process record',
    description:
      'Click on Create. This will create a new process record with basic information, but no process data yet.',
  },
  {
    title: 'Add data tracks',
    description:
      'Open the fresh process record and add process data using the "+"-Button in the data track list. The data can either be from a CSV file, or entered manually.',
  },
];

export const WELCOME_USERS_STEPS: Step[] = [
  {
    title: 'Go to the list of users',
    description: 'Go to the list of users in the User Management module, click on Invite New User.',
  },
  {
    title: 'Enter the required information',
    description:
      'For the creation of a new user, you need to specify a name and an e-mail address. Both the name and the e-mail address cannot be changed afterwards.',
  },
  {
    title: 'Set the user roles',
    description: `By disabling data access, you can create users that cannot see process records or monitoring data, e.g., a user-manager account. Administrators can invite new users to your organization.\n\nBy enabling the multi-factor authentication you can require users to authenticate them with a code send via mail in addition to their e-mail and password.`,
  },
  {
    title: 'Invite user',
    description:
      'Click on Invite User. An invitation will be sent to the e-mail address you specified.',
  },
];

export const WELCOME_MFA_STEPS: Step[] = [
  {
    title:
      'On the log in dialog give your credentials (e-mail and password) as usual, click on Sign In, click on Send verification code.',
  },
  {
    title:
      'You will receive an e-mail from BioNsight <no-reply@cloud.bionsight.eppendorf.com> containing your 6 digit verification code.',
  },
  {
    title:
      'In BioNsight cloud Enter the code into the text field Verification Code, click on Verify code, click on Continue.',
  },
];

export const WELCOME_JMP_IMPORT_STEPS: Step[] = [
  {
    title: 'Open the detail view of a process record',
    description:
      'Open the detail view of the process record you want to export, i.e., the view where the process record is plotted as chart.',
  },
  {
    title: 'Download the process record',
    description:
      'On the top right of the element with the process record information, there is a button to download the process record as JSON-file.',
  },
  {
    title: 'Open the JSON-file in JMP',
    description:
      "Open the freshly downloaded file in the JMP application, which will open JMP's own JSON Import Wizard.",
  },
  {
    title: 'Adjust the import settings',
    description:
      'In order to import the data tracks, use the following import settings: select dataTracks, dataPoints, v, and dataTrackType; set the type of ts and v to numeric, set the type of dataTrackType to character. Set the fill-property to + /root/dataTracks.',
    image: '/assets/images/jmp-import/jmp-import1.png',
    imageDesc: 'The JMP JSON Import Wizard with the appropriate import settings.',
  },
  {
    title: 'Import the data as JMP table',
    description:
      'Click on OK to start the import process which will result in a table with timestamps, values, and data track names.',
  },
  {
    title: 'Convert the timestamps if necessary',
    description:
      'The timestamps in the exported file are UNIX epoch times in ms, i.e., the number of milliseconds elapsed since 1st January 1970. If needed, these can be converted to JMP dates using the following formula:',
    image: '/assets/images/jmp-import/jmp-import3.png',
    imageDesc: 'Conversion of timestamps to JMP dates.',
  },
  {
    title: 'Transpose the table if necessary',
    description:
      'If you prefer having the table in a long format, you can transpose the table using the following settings:',
    image: '/assets/images/jmp-import/jmp-import2.png',
    imageDesc: 'Transpose settings for the JMP table.',
  },
];

export const WELCOME_Y_AXIS_ZOOMING_EXPLANATION_STEPS: Step[] = [
  {
    title: 'Open the detail view of a process record',
    description: 'Open the detail view of the process record you want to analyze.',
  },
  {
    title: 'Set the y-axis range in the data track split view',
    description:
      'Set the y-axis range for each data track separately by entering values on the left hand side of each data track chart.',
    image: '/assets/images/y-axis-zooming-explanation/y-axis-zoom-split.png',
    imageDesc: 'Set the y-axis range in the data data track split view.',
  },
  {
    title: 'Open the modal to set the y-axis range in the combined view',
    description:
      'By clicking each data tracks y-axis label separately a modal opens to set the y-axis range. The modal allows to enter the min and max values used for the specific y-axis.',
    image: '/assets/images/y-axis-zooming-explanation/y-axis-zoom-combined-closed-modal.png',
    imageDesc: 'Open the modal to set the y-axis range in the combined view.',
  },
  {
    title: 'Set the data tracks y-axis range via the modal',
    description: 'Set the y-axis min and max values for the selected data track in the modal.',
    image: '/assets/images/y-axis-zooming-explanation/y-axis-zoom-combined-modal.png',
    imageDesc: 'Set y-axis min and max values via the opened modal.',
  },
];

export const WELCOME_ANALYTICS_SECTION: Section = {
  title: 'Analyze a finished run:',
  icon: <ChartsIcon aria-hidden="true" className="h-6 w-6 text-blue-600" />,
  subsections: [
    {
      title: 'Upload a run from DASware control',
      steps: welcomeGetDWCUploadSteps,
    },
    {
      title: 'Upload a run from DASware control export file',
      steps: welcomeGetFileUploadSteps,
    },
    {
      title: 'Create an empty process record and upload data manually',
      steps: WELCOME_CREATE_PROCESS_RECORD_STEPS,
    },
    {
      title: 'Look at already existing process records',
      link: '/analytics/process-records/',
    },
    {
      title: 'Import a process record to JMP for statistical analysis',
      steps: WELCOME_JMP_IMPORT_STEPS,
    },
    {
      title: 'Set min and max values for y-axis',
      steps: WELCOME_Y_AXIS_ZOOMING_EXPLANATION_STEPS,
    },
  ],
};

export const WELCOME_MONITORING_SECTION: Section = {
  title: 'Monitor a running process:',
  icon: <VesselIcon aria-hidden="true" className="h-6 w-6 text-blue-600" />,
  subsections: [
    {
      title: 'Connect a DASware control',
      steps: welcomeGetDWCSetupSteps,
    },
    {
      title: 'Configure your Monitoring Overview',
      steps: welcomeMonitoringConfigurationSteps,
    },
    {
      title: 'Compare a Current Run to a Reference',
      steps: welcomeCompareCurrentRunsSteps,
    },
    {
      title: 'Go to the monitoring overview',
      link: '/monitoring/setups',
    },
  ],
};

export const WELCOME_USER_MANAGEMENT_SECTION: Section = {
  title: 'Manage users:',
  icon: <UserIcon aria-hidden="true" className="h-6 w-6 text-blue-600" />,
  subsections: [
    {
      title: 'Invite new users',
      steps: WELCOME_USERS_STEPS,
    },
    {
      title: 'Log in with multi-factor authentication',
      steps: WELCOME_MFA_STEPS,
    },
    {
      title: 'Look at the list of users in this organization',
      link: '/user-management/members',
    },
  ],
};
