import { AlarmLimitsIcon, Button, Modal, ModalProps } from '@biss/react-horizon-web';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { AlertLimitsModalContent } from './alert-limits-modal-content';

function AlertLimitsModal({
  title,
}: {
  title: ModalProps['title'];
}): React.FunctionComponentElement<ModalProps['title']> {
  const [isMaxActive, setIsMaxActive] = React.useState(false);
  const [isMinActive, setIsMinActive] = React.useState(false);
  const [min, setMin] = React.useState('');
  const [max, setMax] = React.useState('');

  const handleChangeMaxLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (target) {
      setMax(target.value);
    }
  };
  const handleChangeMinLimit = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (target) {
      setMin(target.value);
    }
  };
  const handleMaxToggle = (on: boolean) => {
    setIsMaxActive(on);
    if (!on) {
      setMax('');
    }
  };
  const handleMinToggle = (on: boolean) => {
    setIsMinActive(on);
    if (!on) {
      setMin('');
    }
  };

  return (
    <Modal
      trigger={
        <Button
          data-testid="alert-limits-modal-trigger"
          leftIcon={<AlarmLimitsIcon />}
          kind="secondary"
          mood="neutral"
        />
      }
      title={title}
      size="xs"
    >
      <Modal.Content>
        <AlertLimitsModalContent
          onChangeMaxLimit={handleChangeMaxLimit}
          onChangeMinLimit={handleChangeMinLimit}
          isMaxActive={isMaxActive}
          isMinActive={isMinActive}
          onToggleMax={handleMaxToggle}
          onToggleMin={handleMinToggle}
          max={max}
          min={min}
        />
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild>
          <Modal.Button>
            <FormattedMessage
              defaultMessage="Cancel"
              id="GPrqao"
              description="Alert limint modal cancel button"
            />
          </Modal.Button>
        </Modal.Close>
        <Modal.Close asChild>
          <Modal.Button
            disabled={(!min && !max) || (!isMaxActive && !isMinActive)}
            variant="highlight"
          >
            <FormattedMessage
              defaultMessage="Save Settings"
              id="prvUia"
              description="Alert limint modal cancel button"
            />
          </Modal.Button>
        </Modal.Close>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default AlertLimitsModal;
