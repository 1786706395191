import React, { useEffect, useState } from 'react';

import { YAxisRange } from '../../../components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';

import AxisDetailModal from '../../../components/time-series-chart/axis-detail-modal';
import { SeriesDescriptor } from '../../../components/time-series-chart/axis-detail-modal/axis-detail-modal.definitions';

import { SeriesName } from '../../chart-groups';

import { CHART_Y_AXIS_ZOOM_SLIDER_ID } from '../../chart-zoom/chart-zoom.definitions';

import YAxisDescriptor from './combined-chart-y-axis-setup.definitions';
import { getYAxisEchartsConfig } from './combined-chart-y-axis-setup.helpers';

export default function useYAxis(
  content: Array<YAxisDescriptor>,
  onResetZoom: (dataZoomId: string) => void,
  defaultYAxisRange?: Map<SeriesName, YAxisRange>,
  onYAxisRangeChange?: (ranges: Map<SeriesName, YAxisRange>) => void,
) {
  const [yAxisRanges, setYAxisRanges] = useState<Map<SeriesName, YAxisRange>>(
    defaultYAxisRange ?? new Map(),
  );
  const [modalOpen, setModalOpen] = useState(false);

  const echartsYAxisConfig = getYAxisEchartsConfig(content, yAxisRanges);

  // call y-axis range callback if it has been changed
  useEffect(() => {
    onYAxisRangeChange?.(yAxisRanges);
    onResetZoom(CHART_Y_AXIS_ZOOM_SLIDER_ID);
  }, [yAxisRanges]);

  function yAxisClickCallback(params: object) {
    if ('yAxisIndex' in params === false || typeof params.yAxisIndex !== 'number') {
      return;
    }

    setModalOpen(true);
  }

  const series = content.reduce(
    (acc, { label, name }) => {
      acc[name] = [
        {
          name,
          label,
        },
      ];

      return acc;
    },
    {} as Record<SeriesName, Array<SeriesDescriptor>>,
  );

  const modal = (
    <AxisDetailModal
      open={modalOpen}
      onOpenChange={(open) => setModalOpen(open)}
      onRangeChange={(range) => {
        setYAxisRanges(range);
      }}
      ranges={yAxisRanges}
      series={series}
    />
  );

  return { modal, echartsYAxisConfig, yAxisClickCallback };
}
