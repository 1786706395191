import { PropsWithChildren, useEffect } from 'react';
import { useAuthContext } from '@biss/react-auth-web';

import useLogger from '../../common/hooks/use-logger/use-logger';
import useBetaFeatureFlags from '../../common/hooks/use-beta-feature-flags';

export default function SetLoggingDimensions({ children }: PropsWithChildren) {
  const logger = useLogger();
  const { account } = useAuthContext();
  const flagValues = useBetaFeatureFlags();

  const values = flagValues && Array.from(flagValues.values()).join('&');
  useEffect(() => {
    logger.setCustomDimensions(account?.organizationId, flagValues);
  }, [account?.organizationId, values]);

  return children;
}
