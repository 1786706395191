import React, { FunctionComponentElement, PropsWithChildren } from 'react';
import { FormattedMessage } from 'react-intl';
import { InfoIcon } from '@biss/react-horizon-web';

import ChartInfoBoxProps from './chart-info-box.definitions';

function InfoMessage({ children }: PropsWithChildren): FunctionComponentElement<PropsWithChildren> {
  return (
    <>
      <InfoIcon className="mr-0.5 h-5 w-5" title="Info" />
      {children}
    </>
  );
}

function ChartInfoBox({
  seriesLength,
  seriesLimit,
  isCombined,
}: ChartInfoBoxProps): FunctionComponentElement<ChartInfoBoxProps> {
  return (
    <div
      className="mx-4 flex items-center justify-end text-xs text-gray-700"
      data-testid="TimeSeriesChart-InfoBox"
    >
      {seriesLength === seriesLimit && isCombined && (
        <InfoMessage>
          <FormattedMessage
            description="Chart info box: Adding more data tracks will split the graph automatically."
            defaultMessage="Adding more data tracks will split the graph automatically."
            id="uNnWHi"
          />
        </InfoMessage>
      )}
      {seriesLength > seriesLimit && !isCombined && (
        <InfoMessage>
          <FormattedMessage
            description="Chart info box: Combined Graph only with 8 or less data tracks selected"
            defaultMessage="Combining graphs into one graph is only possible with max. {maxNumber} selected data tracks."
            id="sHAtAF"
            values={{ maxNumber: seriesLimit }}
          />
        </InfoMessage>
      )}
    </div>
  );
}
export default ChartInfoBox;
