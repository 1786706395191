import React, { useEffect, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Tile, PageLayout, ChevronLeftIcon, Tabs } from '@biss/react-horizon-web';
import { Link } from 'react-router-dom';
import { useMediaQuery } from 'usehooks-ts';

import ProcessRecordHeaderList from '../../../components/process-record-header-list';
import { colorizeProcessRecords } from '../../process-record-comparison/process-record-comparison.helpers';
import ProcessRecordEventList from '../../../components/process-record-event-list/process-record-event-list';
import ProcessRecordAlignmentOptions from '../../../components/process-record-alignment-options';
import useFeatureFlag from '../../../../shared/common/hooks/use-feature-flag';
import FKey from '../../../../shared/common/feature-keys';
import RecipeOptimizationTransfer from '../../../components/recipe-optimization-transfer';
import Footer from '../../../../shared/components/footer';
import useLogger from '../../../../shared/common/hooks/use-logger/use-logger';
import TrackedEvent from '../../../../shared/common/tracked-event';
import RouteDefinition from '../../../../shared/common/routes/routes.definitions';
import useProcessRecordOptimizationVariables from '../../../common/hooks/use-process-record-optimization-variables';
import { HttpStatus } from '../../../../shared/common/types/http';
import ReleaseFeatureFlag from '../../../../shared/common/types/release-feature-flag';
import {
  SHORTCUTS,
  Y_AXIS_TIP,
} from '../../../../shared/components/time-series-chart/time-series-chart.definitions';
import ProcessRecordXAxisFormatOptions from '../../../components/process-record-x-axis-format-options';

import { ChartXAxisTimeStampFormat } from '../../../../shared/charts/chart-formatters/chart-formatters.definitions';

import { useDataTrackSelectionByType } from './process-record-visualization.helpers';
import {
  DEFAULT_DATA_TRACK_TYPES,
  DataTrackTimeAlignment,
  ProcessRecordVisualizationProps,
  TabKey,
} from './process-record-visualization.definitions';
import ProcessRecordVisualizationChart from './process-record-visualization-chart';
import ProcessRecordVisualizationDataTracks from './process-record-visualization-data-tracks';

/**
 * Visualization Component for a single process record
 *
 * includes the selectable data track list and chart
 */
function ProcessRecordVisualization({
  processRecords,
  totalProcessRecordCount = 1,
  pageTitle = 'Process Record',
  defaultSidebarOpen,
  defaultAlignment = DataTrackTimeAlignment.RelativeToStartTime,
  defaultTimeStampFormat = ChartXAxisTimeStampFormat.Accumulative,
}: Readonly<ProcessRecordVisualizationProps>) {
  const inSingleView = totalProcessRecordCount === 1;

  if (processRecords.length <= 0) {
    throw new Error('No Process Records were provided');
  }

  const logger = useLogger();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const [isOpen, setIsOpen] = useState(defaultSidebarOpen ?? isMobile === false);
  const [tab, setTab] = useState<TabKey>('data-track-selection');
  const [showCombinedChart, setShowCombinedChart] = useState(false);

  const isTimestampFormatSelectionEnabled = useFeatureFlag(
    new ReleaseFeatureFlag(FKey.ANALYTICS_REFACTORING_COMBINED_CHART),
  );

  /** can the data how integration area be shown */
  const isDataHowLabAccessible = inSingleView;

  /** key used to cache computations dependant on the {@link processRecords} prop */
  const processRecordsCacheKey = processRecords
    .map(
      (pr) =>
        `${pr.processRecordId}${pr.dataTracks.map((dt) => dt.dataTrackId).join()}${Object.entries(
          pr.attributes,
        )
          .flat()
          .join()}`,
    )
    .join();

  const {
    data: dhlMappings,
    error: dhlMappingsError,
    isError: isDhlMappingError,
    isLoading: isDhlMappingLoading,
    isSuccess: isDhlMappingSuccess,
  } =
    // data how integration is only visible on detail view where there is only one process record available
    useProcessRecordOptimizationVariables(
      processRecords[0].processRecordId,
      isDataHowLabAccessible,
    );

  const isUserMissingDataHowSubscription = Boolean(
    dhlMappingsError &&
      'status' in dhlMappingsError &&
      dhlMappingsError.status === HttpStatus.PaymentRequired,
  );

  const showDataHowLab =
    isDataHowLabAccessible && isDhlMappingSuccess && isUserMissingDataHowSubscription === false;

  const [timeAlignment, setTimeAlignment] = useState(defaultAlignment);

  const [xAxisTimeFormat, setXAxisTimeFormat] = useState(defaultTimeStampFormat);

  // assign a color to each process record
  const coloredProcessRecords = useMemo(
    () => colorizeProcessRecords(processRecords),
    [processRecordsCacheKey],
  );

  // get all the available dataTrackTypes
  const allTypes = useMemo(
    () =>
      Array.from(
        new Set(coloredProcessRecords.flatMap((pr) => pr.dataTracks).map((dt) => dt.dataTrackType)),
      ),
    [processRecordsCacheKey],
  );

  // default data tracks that are available in this process record
  const availableDefaultDataTrackTypes = DEFAULT_DATA_TRACK_TYPES.filter((type) =>
    allTypes.includes(type),
  );

  const { selectedDataTrackTypes, setDataTrackTypeSelection } = useDataTrackSelectionByType(
    availableDefaultDataTrackTypes,
  );

  // update data track selection when additional process records have been loaded
  //  in comparison not all process records might have the default data tracks,
  //  if the first one that is loaded does not contain them, they will be loaded once
  //  a process record containing them was loaded
  useEffect(() => {
    if (processRecords.length === totalProcessRecordCount) {
      setDataTrackTypeSelection(availableDefaultDataTrackTypes);
    }
  }, [processRecords.length === totalProcessRecordCount]);

  const hasInoculationTime = useMemo(
    () =>
      processRecords
        .map((record) => record.inoculationTimestamp)
        .every((timeStamp) => timeStamp !== undefined),
    [processRecordsCacheKey],
  );

  const handleDataTracksSelectionChange = (newSelectedDataTrackTypes: string[]) =>
    setDataTrackTypeSelection(newSelectedDataTrackTypes);

  const dataTrackList = (
    <ProcessRecordVisualizationDataTracks
      canAddCustomDataTrack={inSingleView}
      canDeleteCustomDataTrack={inSingleView}
      coloredProcessRecords={coloredProcessRecords}
      selectedDataTrackTypes={selectedDataTrackTypes}
      onSelectionChange={handleDataTracksSelectionChange}
    />
  );

  const handleAlignmentOptionChange = (value: DataTrackTimeAlignment) => {
    const isAlignmentAbsolute = value === DataTrackTimeAlignment.Absolute;
    const isAbsoluteFormatSelected = xAxisTimeFormat === ChartXAxisTimeStampFormat.Date;

    // if there is a mismatch between the selected format and the new alignment
    if (isAlignmentAbsolute !== isAbsoluteFormatSelected) {
      // set the default format according to whether the alignment is absolute or relative
      setXAxisTimeFormat(
        isAlignmentAbsolute ? ChartXAxisTimeStampFormat.Date : defaultTimeStampFormat,
      );
    }

    setTimeAlignment(value);
  };

  return (
    <PageLayout
      sidebarOpen={isOpen}
      data-testid="ProcessRecordVisualizationLayout"
      className="!h-[calc(100dvh-var(--header-bar-height,0))]"
    >
      <PageLayout.Main>
        <PageLayout.Main.ActionBar
          openText={showDataHowLab ? 'Open Panel' : 'Select Data Tracks'}
          closeText={showDataHowLab ? 'Close Panel' : 'Select Data Tracks'}
          pageTitle={pageTitle}
          sidePanelOpen={isOpen}
          onSidePanelOpenChange={setIsOpen}
          backButton={
            <Link
              to={`${RouteDefinition.Analytics}/process-records`}
              onClick={() => {
                logger.trackEvent(TrackedEvent.GoToProcessRecords);
              }}
            >
              <Button mood="neutral" leftIcon={<ChevronLeftIcon />}>
                <FormattedMessage
                  description="Process record detail view: link to the process records list."
                  defaultMessage="Go to Process Records"
                  id="xaqnRL"
                />
              </Button>
            </Link>
          }
        />
        <PageLayout.Main.Content className="flex flex-col gap-2 overflow-y-auto px-4">
          <ProcessRecordHeaderList
            headers={coloredProcessRecords}
            totalNumberOfHeaders={totalProcessRecordCount}
          />

          <Tile>
            {Object.keys(selectedDataTrackTypes).length > 0 && (
              <Tile.Header className="flex w-full flex-col gap-4 sm:items-start">
                <div className="flex w-full flex-row flex-wrap items-end gap-2">
                  <ProcessRecordAlignmentOptions
                    value={timeAlignment.toString()}
                    onChangeAlignment={handleAlignmentOptionChange}
                    hasInoculationTime={hasInoculationTime}
                  />

                  {isTimestampFormatSelectionEnabled && (
                    <ProcessRecordXAxisFormatOptions
                      value={xAxisTimeFormat.toString()}
                      onChange={setXAxisTimeFormat}
                      alignment={timeAlignment}
                    />
                  )}
                  {inSingleView && (
                    <div className="ml-auto">
                      <Button
                        mood="neutral"
                        onClick={() => {
                          setShowCombinedChart(!showCombinedChart);
                        }}
                        leftIcon={
                          showCombinedChart ? (
                            <svg>
                              <path d="M8.25 15L12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                            </svg>
                          ) : (
                            <svg>
                              <path d="M8.25 5.25L12 9 15.75 5.25m0 13.5L12 15 8.25 18.75" />
                            </svg>
                          )
                        }
                      >
                        {showCombinedChart ? (
                          <FormattedMessage
                            id="7kuysX"
                            defaultMessage="Split"
                            description="Process Record Visualization: Show Combined Graph Button"
                          />
                        ) : (
                          <FormattedMessage
                            id="3mNORU"
                            defaultMessage="Combine"
                            description="Process Record Visualization: Show Combined Graph Button"
                          />
                        )}
                      </Button>
                    </div>
                  )}
                </div>

                {!hasInoculationTime && (
                  <div className="rounded bg-gray-200 px-2 py-1">
                    <FormattedMessage
                      description="ProcessRecordAlignmentOptions: Inoculation time is not available in all process records"
                      defaultMessage="Not all of the selected process records have a set inoculation time. Therefore, alignment along inoculation time is not available."
                      id="hvFP2D"
                    />
                  </div>
                )}
              </Tile.Header>
            )}

            <ProcessRecordVisualizationChart
              inSingleView={inSingleView}
              coloredProcessRecords={coloredProcessRecords}
              selectedDataTrackTypes={selectedDataTrackTypes}
              timeAlignment={timeAlignment}
              xAxisFormat={xAxisTimeFormat}
              chartType={showCombinedChart ? 'combined' : 'split'}
            />

            <Tile.Footer>
              {SHORTCUTS}
              {Y_AXIS_TIP}
            </Tile.Footer>
          </Tile>

          {processRecords.length === 1 && ( // TODO(BIOCL-1587): event-list in comparison view will be implemented in
            <ProcessRecordEventList processRecordId={processRecords[0].processRecordId} />
          )}

          {/* TODO(BIOCL-2732): Move to a central place when the page layout is done */}
          <Footer />
        </PageLayout.Main.Content>
      </PageLayout.Main>
      <PageLayout.Sidebar
        isOpen={isOpen}
        onOpenChange={setIsOpen}
        openText="Open Panel"
        closeText="Close Panel"
      >
        {showDataHowLab === false && (
          <PageLayout.Sidebar.Title>
            <FormattedMessage
              defaultMessage="Data Track Selection"
              id="noSwjL"
              description="Data Track Selection Sidebar Title"
            />
          </PageLayout.Sidebar.Title>
        )}

        {
          // tabs are only shown when the dhl subscription is active, otherwise "data tracks" would be the only tab
          showDataHowLab ? (
            <>
              <Tabs
                defaultValue="data-track-selection"
                value={tab}
                onValueChange={(t) => setTab(t as TabKey)}
              >
                <Tabs.List>
                  <Tabs.Trigger value="data-track-selection">
                    <FormattedMessage
                      defaultMessage="Data Tracks"
                      id="sslnK6"
                      description="Sidebar Tabs: Data Track"
                    />
                  </Tabs.Trigger>

                  <Tabs.Trigger value="data-how-integration">
                    <FormattedMessage
                      defaultMessage="DataHowLab"
                      id="1dxgpZ"
                      description="Sidebar Tabs: Data How Lab"
                    />
                  </Tabs.Trigger>
                </Tabs.List>
              </Tabs>

              {tab === 'data-track-selection' && dataTrackList}

              {tab === 'data-how-integration' && (
                <RecipeOptimizationTransfer
                  mappings={dhlMappings}
                  isError={isDhlMappingError}
                  isLoading={isDhlMappingLoading}
                  processRecordId={processRecords[0].processRecordId}
                  dataTracks={processRecords[0].dataTracks}
                  attributes={processRecords[0].attributes}
                />
              )}
            </>
          ) : (
            dataTrackList
          )
        }
      </PageLayout.Sidebar>
    </PageLayout>
  );
}

export default ProcessRecordVisualization;
