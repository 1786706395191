import React from 'react';

import useProcessRecordEvents from '../../common/hooks/use-process-record-events/use-process-record-events';
import EventList from '../../../shared/components/event-list';

import { ProcessRecordEventListProps } from './process-record-event-list.definitions';

function ProcessRecordEventList({ processRecordId }: ProcessRecordEventListProps) {
  const { data, isLoading, isSuccess } = useProcessRecordEvents(processRecordId);

  return (
    <EventList
      data={
        isSuccess ? { events: data, state: 'success' } : { state: isLoading ? 'loading' : 'error' }
      }
    />
  );
}

export default ProcessRecordEventList;
