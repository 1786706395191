import React, { useMemo } from 'react';

import { LoggingProps } from './logging.definitions';
import { ILogger, createLogger } from './logger';
import { LoggingGlobals } from './logging-globals';

export default function Logging({ children, appInsights }: LoggingProps) {
  const logger: ILogger = useMemo(() => createLogger(appInsights), [appInsights]);

  return <LoggingGlobals.Provider value={logger}>{children(logger)}</LoggingGlobals.Provider>;
}
