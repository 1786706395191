import { ApplicationInsights, SeverityLevel } from '@microsoft/applicationinsights-web';

import { ILogger } from './logger.definitions';

class ApplicationInsightsLogger implements ILogger {
  constructor(private readonly appInsights: ApplicationInsights) {}

  public setCustomDimensions(
    organizationId: string | undefined,
    betaFlags: Map<string, boolean>,
  ): void {
    if (!organizationId && betaFlags.size <= 0) {
      return;
    }
    this.appInsights.addTelemetryInitializer((envelope) => {
      if (envelope.baseData) {
        const data = envelope.baseData;
        data.properties = { ...data.properties, ...Object.fromEntries(betaFlags), organizationId };
      }
    });
  }

  public error(message: string) {
    this.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Error });
  }

  public warn(message: string) {
    this.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Warning });
  }

  public info(message: string) {
    this.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Information });
  }

  public debug(message: string) {
    this.appInsights.trackTrace({ message, severityLevel: SeverityLevel.Verbose });
  }

  public trackEvent(name: string) {
    this.appInsights.trackEvent({ name });
  }
}

function createApplicationInsightsLogger(appInsights: ApplicationInsights) {
  return new ApplicationInsightsLogger(appInsights);
}

export default createApplicationInsightsLogger;
