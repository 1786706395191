import { ILogger } from '../../../globals/logging';
import fetchData from '../../fetch-data';
import { HttpMethods } from '../../types/http';
import { joinPaths } from '../../../utils';
import { PROJECT_CONFIG_URL } from '../../../globals/project-config/project-config.definitions';

import { FeatureFlagValue, featureFlagValueSchema } from './use-feature-flag.definitions';

export function createGetFeatureFlagUrl(featureFlagId: string): string {
  return joinPaths(PROJECT_CONFIG_URL, 'flag', encodeURIComponent(featureFlagId));
}

export async function fetchFeatureFlag(
  featureFlagId: string,
  logging: ILogger,
  accessToken: string,
): Promise<FeatureFlagValue> {
  const result = await fetchData(createGetFeatureFlagUrl(featureFlagId), {
    method: HttpMethods.Get,
    headers: {
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });

  const parsed = featureFlagValueSchema.safeParse(result);
  if (parsed.success === false) {
    const errorMessage = `Flag ${featureFlagId} was not in the shape of a [fetchFeatureFlagSchema]`;
    logging.warn(errorMessage);
    return false;
  }

  const value = parsed.data;
  logging.debug(`Flag: ${featureFlagId} --> ${value}`);

  return parsed.data;
}
