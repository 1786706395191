import { useIntl } from 'react-intl';

import useHorizontalZoom from '../../../components/time-series-chart/use-horizontal-zoom';
import {
  SPLIT_CHART_RIGHT_OFFSET_PX,
  SPLIT_CHART_X_AXIS_ZOOM_HEIGHT,
  SPLIT_CHART_ZOOM_OFFSET_PX,
} from '../split-chart.config';
import { getMinValueSpan, getOnZoomCallback } from '../../chart-zoom';
import { ChartXAxisTimeStampFormat } from '../../chart-formatters/chart-formatters.definitions';
import { getXAxisFormatter } from '../../chart-formatters/chart-formatters';
import {
  CHART_ALL_AXIS_SLIDERS,
  CHART_X_AXIS_ZOOM_INSIDE_ID,
  CHART_X_AXIS_ZOOM_SLIDER_ID,
  CHART_Y_AXIS_ZOOM_SLIDER_ID,
  initialZoom,
} from '../../chart-zoom/chart-zoom.definitions';

/**
 * Configures the Echarts settings for the y axis
 *
 * @returns y axis configuration object to configure Echarts with
 */
export function createEchartsDataZoomConfig(
  zoomX: { start: number; end: number },
  zoomY: { start: number; end: number },
  dateFormatter: (timeSpan: number) => string,
  minValueSpan?: number,
) {
  return [
    {
      id: CHART_X_AXIS_ZOOM_SLIDER_ID,
      type: 'slider',
      orient: 'horizontal',
      // the split chart only contains a single x axis
      xAxisIndex: [0, 0],
      start: zoomX.start,
      end: zoomX.end,
      filterMode: 'none',
      left: SPLIT_CHART_ZOOM_OFFSET_PX,
      right: SPLIT_CHART_RIGHT_OFFSET_PX,
      showDataShadow: false,
      height: SPLIT_CHART_X_AXIS_ZOOM_HEIGHT,
      bottom: 0,
      zoomLock: false,
      labelFormatter: dateFormatter,
      brushSelect: false,
      minValueSpan,
    },
    {
      type: 'inside',
      id: CHART_X_AXIS_ZOOM_INSIDE_ID,
      orient: 'horizontal',
      start: zoomX.start,
      end: zoomX.end,
      // the split chart only contains a single x axis
      xAxisIndex: [0, 0],
      rangeMode: ['value', 'percent'],
      zoomOnMouseWheel: 'shift',
      moveOnMouseWheel: 'ctrl',
      moveOnMouseMove: true,
      preventDefaultMouseMove: false,
      filterMode: 'none',
      labelFormatter: dateFormatter,
      minValueSpan,
    },
    {
      type: 'slider',
      id: CHART_Y_AXIS_ZOOM_SLIDER_ID,
      rangeMode: ['percent', 'percent'],
      // the split chart contains a y axis for each line in the chart
      yAxisIndex: 0,
      start: zoomY.start,
      end: zoomY.end,
      orient: 'vertical',
      filterMode: 'none',
      show: true,
      showDataShadow: false,
      brushSelect: false,
      showDetail: false,
      right: 5,
    },
  ];
}

/**
 * Configuration to set up the zoom for the split chart.
 *
 *  This is required to configure the charts layout and the echarts zoom configuration.
 */
function useZoom(timeStampFormat: ChartXAxisTimeStampFormat) {
  // TODO: (BIOCL-4457) move zoom hook into chart "package" and fix naming (zoomY -> useHORIZONTALZoom)
  const zoomX = useHorizontalZoom();
  const zoomY = useHorizontalZoom();
  const intl = useIntl();

  const dateFormatter = getXAxisFormatter(timeStampFormat, intl);
  const minValueSpan = getMinValueSpan(timeStampFormat);

  const onZoomCallback = getOnZoomCallback(zoomX, zoomY);

  const onResetZoom = (dataZoomId: string = CHART_ALL_AXIS_SLIDERS) => {
    onZoomCallback({ ...initialZoom, dataZoomId });
  };

  const getChartZoomConfig = () =>
    createEchartsDataZoomConfig(zoomX.current, zoomY.current, dateFormatter, minValueSpan);

  return { onZoomCallback, onResetZoom, getChartZoomConfig };
}

export default useZoom;
