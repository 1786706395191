import React, { useEffect, useRef, useState } from 'react';
import { EChartsType, init } from 'echarts';
import { useIntl } from 'react-intl';

import { Button, ZoomResetIcon } from '@biss/react-horizon-web';

import { SPLIT_CHART_TOTAL_HEIGHT } from '../split-chart.config';
import useZoom from '../split-chart-zoom-setup';

import {
  CHART_Y_AXIS_ZOOM_SLIDER_ID,
  DataZoomCallbackParam,
  initialZoom,
} from '../../chart-zoom/chart-zoom.definitions';
import { getTooltipEchartsConfig } from '../../chart-tooltip';
import { getXAxisEchartsConfig } from '../../chart-x-axis';
import { getDatasetEchartsConfig } from '../../chart-dataset';

import {
  getDataCacheKey,
  getMarkLinesCacheKey,
  getToolboxEchartsConfig,
} from '../../charts.helpers';

import { SplitChartItemProps } from './split-chart-item.definitions';
import {
  getGridEchartsConfig,
  getLegendEchartsConfig,
  getSeriesEchartsConfig,
  getSplitChartLayout,
  getTitleConfig,
} from './split-chart.helpers';

function SplitChartItem({
  data,
  startTime,
  stopTime,
  echartsYAxisConfig,
  yAxisClickCallback,
  timeStampFormat,
  markLines,
  width,
}: SplitChartItemProps) {
  const intl = useIntl();

  const root = useRef<HTMLDivElement>(null);
  const [chart, setChart] = useState<EChartsType>();
  const { onZoomCallback, onResetZoom, getChartZoomConfig } = useZoom(timeStampFormat);

  const handleResetZoom = () => {
    onResetZoom();
    chart?.setOption(
      {
        dataZoom: [initialZoom, initialZoom, initialZoom],
      },
      { lazyUpdate: true },
    );
  };

  /**
   * Construct a cache key that can be used in hooks that depend on the data prop.
   *
   *  The key should contain of unique features of the data prop and should therefore only
   *    trigger dependent code when the data actually changes.
   *  See https://www.benmvp.com/blog/object-array-dependencies-react-useEffect-hook/
   */

  const dataCacheKey = getDataCacheKey(data);

  /**
   * Construct a cache key that can be used in hooks that depend on the data prop.
   */
  const markLinesCacheKey = getMarkLinesCacheKey(markLines);

  /**
   * Construct a cache key that can be used in hooks that depend on the data prop.
   */
  const yAxisRangeCacheKey = `${echartsYAxisConfig.name}${echartsYAxisConfig.min}${echartsYAxisConfig.max}`;

  /** Returns all echarts options that are dependent on the data prop. */
  const getDataOptions = () => ({
    toolbox: getToolboxEchartsConfig(),
    legend: getLegendEchartsConfig(data),
    grid: getGridEchartsConfig(),
    tooltip: getTooltipEchartsConfig(timeStampFormat, intl),
    dataset: getDatasetEchartsConfig(data),
    series: getSeriesEchartsConfig(data, markLines),
    dataZoom: getChartZoomConfig(),
    yAxis: echartsYAxisConfig,
    title: getTitleConfig(data),
  });

  /** Returns all echarts options that are dependent on the startTime and stopTime props. */
  const getStartAndStopTimeOptions = () => ({
    xAxis: getXAxisEchartsConfig({ startTime, stopTime }, timeStampFormat, intl),
  });

  // initialize
  useEffect(() => {
    // this will attempt to initialize the chart twice in strict mode but fail gracefully with a warning
    const initializedChart = init(root.current, 'horizon-web', {
      height: SPLIT_CHART_TOTAL_HEIGHT,
    });

    // set initial chart options
    initializedChart.setOption(
      {
        animation: false,
        ...getStartAndStopTimeOptions(),
        ...getDataOptions(),
      },
      { lazyUpdate: true },
    );
    // add event listeners
    initializedChart.on('datazoom', (e) => {
      onZoomCallback(e as DataZoomCallbackParam);
    });

    initializedChart.on('click', (params: object) => {
      yAxisClickCallback(params);
    });

    setChart(initializedChart);
  }, []);

  // update width of the chart if the screen size changes
  useEffect(() => {
    chart?.resize({ width });
  }, [width]);

  /** properties dependent on {@link dataCacheKey} */
  useEffect(() => {
    chart?.setOption(
      {
        ...getDataOptions(),
      },
      // When data is selected/deselected all y axis related options need to be overridden.
      //  Otherwise y axes are rendered even though the related data is not shown.
      { lazyUpdate: true, replaceMerge: ['yAxis', 'series', 'dataZoom'] },
    );
  }, [dataCacheKey]);

  /** properties dependent on {@link startTime} and {@link stopTime} */
  useEffect(() => {
    chart?.setOption(
      {
        ...getStartAndStopTimeOptions(),
      },
      { lazyUpdate: true },
    );
  }, [startTime, stopTime, timeStampFormat]);

  /** properties dependant on {@link markLines} */
  useEffect(() => {
    chart?.setOption(
      {
        series: getSeriesEchartsConfig(data, markLines),
      },
      { lazyUpdate: true },
    );
  }, [markLinesCacheKey]);

  /** properties dependent on {@link yAxisRangeCacheKey} */
  useEffect(() => {
    onResetZoom(CHART_Y_AXIS_ZOOM_SLIDER_ID);
    chart?.setOption(
      {
        yAxis: echartsYAxisConfig,
        dataZoom: [{}, {}, initialZoom],
      },
      { lazyUpdate: true },
    );
  }, [yAxisRangeCacheKey]);

  const { cols, rows } = getSplitChartLayout();

  return (
    <div className="relative w-full">
      <div
        data-testid="split-chart"
        ref={root}
        style={{
          // has to be set so that certain elements do not need to be offset manually
          height: `${SPLIT_CHART_TOTAL_HEIGHT}px`,
          width: '100%',
        }}
      />
      <div
        className="pointer-events-none absolute bottom-0 left-0 right-0 top-0 grid w-full"
        style={{
          gridTemplateRows: rows,
          gridTemplateColumns: cols,
        }}
      >
        {/* intractable elements should explicitly be set to have the "pointer-events-auto" class */}
        <div className="pointer-events-auto col-start-1 row-start-6 flex items-center justify-end p-1">
          <Button
            data-testid="split-chart-reset-zoom"
            kind="ghost"
            rightIcon={<ZoomResetIcon />}
            onClick={handleResetZoom}
          />
        </div>
      </div>
    </div>
  );
}

export default SplitChartItem;
