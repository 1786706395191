import { GroupedSeriesYAxisLabel, YAxisDescriptor } from './chart-y-axis.definitions';

// eslint-disable-next-line import/prefer-default-export -- helper file
export function getYAxisDescriptors<T extends GroupedSeriesYAxisLabel>(
  groupedSeries: Record<string, Array<T>>,
): YAxisDescriptor[] {
  return Object.entries(groupedSeries).map<YAxisDescriptor>(([groupName, series]) => ({
    name: groupName,
    label: series.at(0)?.yAxisLabel ?? groupName,
  }));
}
