import { Checkbox, IndicatorAlertIcon, Input, ToggleSwitch } from '@biss/react-horizon-web';
import classNames from 'classnames';
import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { AlertLimitsModalContentProps } from './alert-limits-modal-content.definitions';

function AlertLimitsModalContent({
  onChangeMaxLimit,
  onChangeMinLimit,
  isMaxActive = false,
  isMinActive = false,
  min = '',
  max = '',
  onToggleMax,
  onToggleMin,
}: AlertLimitsModalContentProps): React.FunctionComponentElement<AlertLimitsModalContentProps> {
  const enableNotifications = isMaxActive || isMinActive;
  const intl = useIntl();
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <div className="flex h-16 w-16 items-center justify-center bg-red">
        <IndicatorAlertIcon width={32} color="white" />
      </div>
      <div className="relative">
        <div
          className={classNames(
            `after:content[' '] h-32 w-16 border-y border-light bg-[#D9D9D9] before:absolute before:left-16 before:right-0 before:top-0 before:h-[1px] before:w-32 before:bg-gray after:absolute after:bottom-0 after:left-16 after:right-0 after:h-[1px] after:w-32 after:bg-gray`,
          )}
        />
        <div className="absolute -top-3 left-20 flex items-center gap-4">
          <ToggleSwitch
            data-testid="alert-limits-max-toggle"
            id="max"
            onChange={onToggleMax}
            checked={isMaxActive}
            label={null}
          />
        </div>
        <div className="absolute -top-8 left-36 flex items-center gap-4">
          <Input
            disabled={!isMaxActive}
            value={max}
            label={intl.formatMessage({
              description: 'Alert limint modal cancel button',
              defaultMessage: 'High Alert',
              id: 'SKu+ag',
            })}
            onChange={onChangeMaxLimit}
            className="!w-16"
            style={{ width: '100%' }}
          />
        </div>
        <div className="absolute -bottom-2 left-20 flex items-center gap-4">
          <ToggleSwitch
            id="max"
            data-testid="alert-limits-min-toggle"
            onChange={onToggleMin}
            checked={isMinActive}
            label={null}
          />
        </div>
        <div className="absolute -bottom-4 left-36 flex items-center gap-4">
          <Input
            disabled={!isMinActive}
            value={min}
            label={intl.formatMessage({
              description: 'Alert limint modal cancel button',
              defaultMessage: 'Low Alert',
              id: 'hIjnn+',
            })}
            onChange={onChangeMinLimit}
            className="!w-16"
            style={{ width: '100%' }}
          />
        </div>
      </div>
      <div className="flex h-16 w-16 items-center justify-center bg-red">
        <IndicatorAlertIcon color="white" width={32} />
      </div>
      <div className="flex flex-col items-start gap-2 self-start">
        <span className={classNames('font-bold', { 'text-secondary': !enableNotifications })}>
          <FormattedMessage
            defaultMessage="Notifications"
            id="m40/VX"
            description="Notifications headline for set alert limits"
          />
        </span>
        {/* eslint-disable-next-line jsx-a11y/label-has-associated-control -- disabled for custom label */}
        <label
          className={classNames('flex items-center gap-2 text-gray-900', {
            'text-secondary': !enableNotifications,
          })}
        >
          <Checkbox checked={enableNotifications} disabled />
          <span>
            <FormattedMessage
              description="Label for send e-mail notifications checkbox"
              defaultMessage="Enable e-mail notifications for these alerts"
              id="OmAA8N"
            />
          </span>
        </label>
      </div>
    </div>
  );
}

export default AlertLimitsModalContent;
