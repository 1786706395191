import {
  DATA_TRACK_EMPTY_TYPE,
  PreloadDataTrackTypeFlags,
} from './use-multiple-process-record.definitions';

/**
 * map data track preload flags to the correct input parameter
 * @param flags
 */
// eslint-disable-next-line import/prefer-default-export -- helpers file
export const mapFlagsToGetProcessRecordIdParameters = (flags: PreloadDataTrackTypeFlags) => {
  if (flags === 'all') {
    return undefined;
  }

  if (flags === 'none') {
    return [DATA_TRACK_EMPTY_TYPE];
  }

  return flags;
};
