import React, { useEffect, useState } from 'react';

import { YAxisRange } from '../../../components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';
import AxisDetailModal from '../../../components/time-series-chart/axis-detail-modal';
import { SeriesDescriptor } from '../../../components/time-series-chart/axis-detail-modal/axis-detail-modal.definitions';

import SideToolbox from '../side-toolbox';

import { SeriesName } from '../../chart-groups';

import YAxisDescriptor from './split-chart-y-axis-setup.definitions';
import { getYAxisEchartsConfig } from './split-chart-y-axis-setup.helpers';

export default function useYAxis(
  content: Array<YAxisDescriptor>,
  defaultYAxisRange?: Map<SeriesName, YAxisRange>,
  onYAxisRangeChange?: (ranges: Map<SeriesName, YAxisRange>) => void,
) {
  const [yAxisRanges, setYAxisRanges] = useState<Map<SeriesName, YAxisRange>>(
    defaultYAxisRange ?? new Map(),
  );
  const [rangeModalOpen, setRangeModalOpen] = useState(false);

  const echartsYAxisConfig = getYAxisEchartsConfig(content, yAxisRanges);

  function yAxisClickCallback(params: object) {
    if ('yAxisIndex' in params === false || typeof params.yAxisIndex !== 'number') {
      return;
    }

    setRangeModalOpen(true);
  }

  // call y-axis range callback if y-axis range has been changed
  useEffect(() => {
    onYAxisRangeChange?.(yAxisRanges);
  }, [yAxisRanges]);

  const handleYAxisRangeChange = (newRange: YAxisRange, seriesType: SeriesName) => {
    setYAxisRanges((prev) => {
      const newMap = new Map(prev);
      newMap.set(seriesType, newRange);
      return newMap;
    });
  };

  const series = content.reduce(
    (acc, { label, name }) => {
      acc[name] = [
        {
          name,
          label,
        },
      ];

      return acc;
    },
    {} as Record<SeriesName, Array<SeriesDescriptor>>,
  );

  const sideToolbox = Object.keys(series).map((item) => (
    <SideToolbox
      onRangeChange={handleYAxisRangeChange}
      range={yAxisRanges.get(item) ?? {}}
      seriesName={item}
      key={item}
    />
  ));

  const modal = (
    <AxisDetailModal
      open={rangeModalOpen}
      onOpenChange={(open) => setRangeModalOpen(open)}
      onRangeChange={(range) => {
        setYAxisRanges(range);
      }}
      ranges={yAxisRanges}
      series={series}
    />
  );

  return { modal, sideToolbox, echartsYAxisConfig, yAxisClickCallback };
}
