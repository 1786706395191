import { HttpStatus } from '../types/http';

/** http error codes that should never be retried */
export const NO_RETRY_STATUS_CODES = [
  HttpStatus.PaymentRequired,
  HttpStatus.Forbidden,
  HttpStatus.BadRequest,
  HttpStatus.NotFound,
];

/** how many times queries should retry failure responses */
export const QUERY_RETRY_COUNT = 3;

/** stale time in milliseconds */
export const QUERY_STALE_TIME = 1_000;

export type RetryFunction = (failureCount: number, error: Error) => boolean;
