import { ChartGroupDefinition, ChartSeriesName } from './chart-groups.definitions';

/** creates a map from group names to multiple series names */
// eslint-disable-next-line import/prefer-default-export -- helper file
export function createGroups<T extends ChartSeriesName>(
  data: T[],
  groups: ChartGroupDefinition = {},
): Record<string, T[]> {
  const groupedSeriesNames = Object.values(groups).flat();
  const notGroupedSeries = data.filter(
    (series: T) => groupedSeriesNames.includes(series.name) === false,
  );

  const multipleMemberGroups: [string, T[]][] = Object.entries(groups).map(
    ([groupName, seriesNames]) => [
      groupName,
      seriesNames.flatMap((seriesName) =>
        data.filter((seriesData) => seriesData.name === seriesName),
      ),
    ],
  );

  const singleMemberGroups: [string, T[]][] = notGroupedSeries.map((series) => [
    series.name,
    [series],
  ]);

  return Object.fromEntries(multipleMemberGroups.concat(singleMemberGroups));
}
