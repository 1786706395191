import React, { useEffect, useRef, useState } from 'react';

import { ChartXAxisTimeStampFormat } from '../chart-formatters/chart-formatters.definitions';

import DebouncedResizeObserver from '../../utils/debounced-resize-observer';

import { createGroups } from '../chart-groups/chart-groups';

import { getYAxisDescriptors } from '../chart-y-axis';

import { SplitChartProps } from './split-chart.definitions';

import useYAxis from './split-chart-y-axis-setup';
import SplitChartItem from './split-chart-item';

function SplitChart({
  data,
  groups,
  startTime,
  stopTime,
  timeStampFormat = ChartXAxisTimeStampFormat.Date,
  markLines = [],
  defaultYAxisRange,
  onYAxisRangeChange,
}: SplitChartProps) {
  if (!data.length) {
    throw new TypeError(
      `The split chart component cannot be called with an empty data array!
      Render a different component (e.g. the placeholder chart) when no data is present.`,
    );
  }
  const chartWrapper = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState<number>(0);

  const groupedSeries = createGroups(data, groups);
  const groupNames = Object.keys(groupedSeries);
  const yAxisDescriptors = getYAxisDescriptors(groupedSeries);

  const { modal, sideToolbox, echartsYAxisConfig, yAxisClickCallback } = useYAxis(
    yAxisDescriptors,
    defaultYAxisRange,
    onYAxisRangeChange,
  );

  useEffect(() => {
    const resizeObserver = new DebouncedResizeObserver({
      threshold: 20,
      callback: (entries) => {
        const newWidth = entries[0].contentRect.width;
        setWidth(newWidth);
      },
    });

    if (chartWrapper.current) {
      resizeObserver.observe(chartWrapper.current);
    }

    return () => {
      if (chartWrapper.current) {
        resizeObserver.unobserve(chartWrapper.current);
      }
    };
  }, []);

  return (
    <div
      className="flex flex-col gap-y-4 md:grid md:grid-cols-[6rem,1fr]"
      style={{
        gridTemplateRows: `repeat(${groupNames.length}, 1fr)`,
      }}
    >
      {modal}
      {sideToolbox}
      <div
        ref={chartWrapper}
        className="col-start-2 col-end-2 row-start-1 row-end-[-1] grid gap-y-4 overflow-hidden"
      >
        {groupNames.map((item, index) => (
          <SplitChartItem
            data={groupedSeries[item]}
            startTime={startTime}
            stopTime={stopTime}
            echartsYAxisConfig={echartsYAxisConfig[index]}
            yAxisClickCallback={yAxisClickCallback}
            timeStampFormat={timeStampFormat}
            key={item}
            markLines={markLines}
            width={width}
          />
        ))}
      </div>
    </div>
  );
}

export default SplitChart;
