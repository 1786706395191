import React, { useRef, useState } from 'react';
import { Modal, NotificationMessage } from '@biss/react-horizon-web';
import { FormattedMessage, useIntl } from 'react-intl';
import { ColumnDef, Row } from '@tanstack/react-table';

import EditableDataTable from '../../../../../../shared/components/editable-data-table';
import {
  DateCell,
  TextCell,
} from '../../../../../../shared/components/editable-data-table/editable-data-table-cell';
import { useUpdateDataTrackDataPoints } from '../../../../../common/hooks';

import {
  addDataPointHandler,
  checkForDuplicates,
  checkForInValids,
  convertDataPointsToDraft,
  convertDraftToDataPoints,
  deleteDataHandler,
  updateDataHandler,
} from './edit-data-track-modal.helpers';
import { DraftDataPoint, EditDataTrackModalProps } from './edit-data-track-modal.definitions';

function EditDataTrackModal({
  processRecordId,
  dataTrack,
  open,
  onOpenChange,
  defaultDraftDataPoints,
  defaultSelectedRowIndex,
}: EditDataTrackModalProps) {
  const intl = useIntl();

  // used to scroll to the error when an error occurs
  const topDivRef = useRef<HTMLDivElement>(null);

  // used to scroll to the bottom of the table when a new row is added
  const bottomDivRef = useRef<HTMLDivElement>(null);

  const [valuesAreValid, setValuesAreValid] = useState(true);
  const [noDuplicatedDataPoints, setNoDuplicatedDataPoints] = useState(true);

  const {
    mutate: updateDataPoints,
    isPending,
    error,
  } = useUpdateDataTrackDataPoints(processRecordId, dataTrack.dataTrackId);

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | undefined>(
    defaultSelectedRowIndex,
  );

  const [draftDataPoints, setDraftDataPoints] = useState<DraftDataPoint[]>(
    () => defaultDraftDataPoints ?? convertDataPointsToDraft(dataTrack.dataPoints),
  );
  const { duplicateDataPoints, duplicateIndexes, duplicatesErrorMessage } = checkForDuplicates(
    draftDataPoints,
    intl,
  );

  const { invalidDataPoints, invalidValueIndexes, invalidValuesErrorMessage } = checkForInValids(
    draftDataPoints,
    intl,
  );

  const invalidDirtyIndexes = invalidDataPoints.flatMap(([dp, index]) =>
    dp.isValueDirty ? index : [],
  );

  const isFormDirty = draftDataPoints.some((dp) => dp.isTimeStampDirty || dp.isValueDirty);

  const errorMessage = `${noDuplicatedDataPoints ? '' : duplicatesErrorMessage}\n${
    valuesAreValid ? '' : invalidValuesErrorMessage
  }`;

  // disable all but selected
  const allButSelectedIndexes = draftDataPoints.flatMap((_, index) =>
    selectedRowIndex === index ? [] : index,
  );

  // table columns configuration
  const columns: ColumnDef<DraftDataPoint>[] = [
    {
      accessorKey: 'timeStamp' satisfies keyof DraftDataPoint,
      header: intl.formatMessage({
        defaultMessage: 'Time Point',
        description: 'Add data points table: Time Point column header',
        id: 'iDtkGo',
      }),
      cell: DateCell,
      meta: {
        error: duplicateIndexes,
        disabled: allButSelectedIndexes,
      },
    },
    {
      accessorKey: 'value' satisfies keyof DraftDataPoint,
      header: `${dataTrack.dataTrackType} ${
        dataTrack.engineeringUnit && `(${dataTrack.engineeringUnit})`
      }`,
      cell: TextCell,
      meta: {
        type: 'number',
        error: invalidDirtyIndexes,
        disabled: allButSelectedIndexes,
      },
    },
  ];

  const handleOpenChange = (isOpen: boolean) => {
    if (isOpen) {
      onOpenChange(true);
      return;
    }

    if (
      // if the form is not dirty skip confirmation
      isFormDirty &&
      // eslint-disable-next-line no-restricted-globals, no-alert -- TODO: replace with a component
      confirm(
        intl.formatMessage({
          defaultMessage:
            'Closing the dialog will discard all the changes made to the table, do you wish to close the dialog anyway?',
          id: 'lr2rhJ',
          description: 'Add data points table: Close Warning',
        }),
      ) === false
    ) {
      return;
    }

    onOpenChange(false);
  };

  const handleSave = () => {
    const invalidValues = invalidValueIndexes.length > 0;
    const invalidDates = duplicateDataPoints.length > 0;
    setValuesAreValid(!invalidValues);
    setNoDuplicatedDataPoints(!invalidDates);

    if (invalidValues || invalidDates) {
      return;
    }
    setSelectedRowIndex(undefined);

    updateDataPoints(convertDraftToDataPoints(draftDataPoints), {
      onSuccess(newData) {
        setDraftDataPoints(convertDataPointsToDraft(newData));
      },
      onError() {
        // execute task on the next event loop
        Promise.resolve().then(() => topDivRef.current?.scrollIntoView(false));
      },
    });
  };

  const handleAddRow = () => {
    setDraftDataPoints((prev) => addDataPointHandler(prev));

    // execute task on the next event loop
    Promise.resolve().then(() => bottomDivRef.current?.scrollIntoView(false));
  };

  const handleDeleteDataPoint = () => {
    setDraftDataPoints((prev) => deleteDataHandler(prev));
  };

  const handleUpdateData = (rowIndex: number, columnId: string, value: unknown) => {
    setDraftDataPoints((prev) => updateDataHandler(prev, rowIndex, columnId, value));
  };

  const handleDeleteSelectedRow = (rowIndex: number) => {
    setSelectedRowIndex((prev) => (selectedRowIndex === rowIndex ? undefined : prev));
    setDraftDataPoints((prev) => prev.slice(0, rowIndex).concat(prev.slice(rowIndex + 1)));
  };

  const handleRowClick = ({ index }: Row<DraftDataPoint>) => {
    setSelectedRowIndex((prev) => (selectedRowIndex === index ? prev : index));
  };

  return (
    <Modal
      open={open}
      onOpenChange={handleOpenChange}
      size="md"
      title={
        <FormattedMessage
          defaultMessage="Edit Data Track"
          id="f8z6uP"
          description="Process Record Visualization Data Tracks: Edit Data Track Modal Title"
        />
      }
    >
      <Modal.Content className="max-h-[70vh] overflow-auto">
        <div role="none" ref={topDivRef} />

        {error && (
          <div className="mb-4">
            <NotificationMessage
              status="error"
              title={intl.formatMessage({
                defaultMessage: 'Could not save your changes.',
                id: 'd+oBJR',
                description: 'Edit Data Track Modal Failure Title',
              })}
            >
              {error.message}
            </NotificationMessage>
          </div>
        )}

        <EditableDataTable
          columns={columns}
          data={draftDataPoints}
          addRow={handleAddRow}
          showDeleteButton
          showDeleteSelectedButton={isPending === false}
          deleteRow={handleDeleteDataPoint}
          updateTableData={handleUpdateData}
          deleteSelectedRow={handleDeleteSelectedRow}
          onRowClick={handleRowClick}
          errorMessage={errorMessage}
        />

        <div role="none" ref={bottomDivRef} />
      </Modal.Content>

      <Modal.ButtonGroup>
        <Modal.Close asChild role="button">
          <Modal.Button data-testid="cancelDataTrackButton">
            <FormattedMessage
              description="Add Data Points: label for cancel button"
              defaultMessage="Cancel"
              id="9f8vwg"
            />
          </Modal.Button>
        </Modal.Close>

        <Modal.Button
          variant="highlight"
          onClick={handleSave}
          data-testid="createDataTrackButton"
          isLoading={isPending}
        >
          <FormattedMessage
            description="Save Data Points: label for save Data Points button"
            defaultMessage="Save"
            id="ne1nAT"
          />
        </Modal.Button>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default EditDataTrackModal;
