import { palettes } from '@biss/react-horizon-web';
import { useState } from 'react';

import { DataTrack, DataTrackType } from '../../../../shared/common/types/process-record';
import {
  DataPointObject,
  DataTrackId,
} from '../../../../shared/common/types/process-record/data-track';

export function getFinalDate(dates: Date[]): Date {
  if (dates.length === 0) {
    throw new TypeError('The given dates may not be empty');
  }

  return dates.reduce((pre, cur) => (pre < cur ? cur : pre));
}

export function filterDataTracksByMultipleTypes(
  dataTracks: Array<DataTrack>,
  types: Array<DataTrackType>,
): Array<DataTrack> {
  return dataTracks.filter((dataTrack) => types.includes(dataTrack.dataTrackType));
}

export function filterDataTracksByType(
  dataTracks: Array<DataTrack>,
  type: DataTrackType,
): Array<DataTrack> {
  return filterDataTracksByMultipleTypes(dataTracks, [type]);
}

export function filterDataTrackById(dataTracks: Array<DataTrack>, ids: Array<DataTrackId>) {
  return dataTracks.filter((dataTrack) => ids.includes(dataTrack.dataTrackId));
}

const colorPalette = [...palettes.categorical] as const;
type Color = (typeof colorPalette)[number];
export function useDataTrackColorization(initialDataTrackTypes: DataTrackType[]): {
  allColors: Map<DataTrackType, Color>;
  getColor: (dataTrackType: DataTrackType) => Color;
} {
  const initialColors = Object.assign(
    {},
    ...initialDataTrackTypes.map((type, i) => ({ [type]: colorPalette[i] })),
  );

  const [allColors, setAllColors] = useState(
    new Map<DataTrackType, Color>(Object.entries(initialColors)),
  );

  const getColor = (dataTrackType: DataTrackType) => {
    if (allColors.has(dataTrackType)) {
      const color = allColors.get(dataTrackType);

      if (color === undefined) {
        throw new TypeError(`Color for data track ${dataTrackType} is set to undefined.`);
      }

      return color;
    }

    const assignedColor = colorPalette[allColors.size % colorPalette.length];
    setAllColors((colors) => colors.set(dataTrackType, assignedColor));

    return assignedColor;
  };

  return { allColors, getColor };
}

export function useDataTrackSelectionByType(dataTrackTypes: Array<DataTrackType> = []): {
  selectedDataTrackTypes: Array<DataTrackType>;
  toggleDataTrackType: (type: DataTrackType) => void;
  setDataTrackTypeSelection: (types: Array<DataTrackType>) => void;
} {
  const [selectedDataTrackTypes, setSelectedDataTrackTypes] =
    useState<Array<DataTrackType>>(dataTrackTypes);

  function toggleDataTrackType(type: DataTrackType): void {
    if (selectedDataTrackTypes.indexOf(type) > -1) {
      setSelectedDataTrackTypes(
        selectedDataTrackTypes.filter((currentType) => currentType !== type),
      );
    } else {
      setSelectedDataTrackTypes([...selectedDataTrackTypes, type]);
    }
  }

  function setDataTrackTypeSelection(types: Array<DataTrackType>): void {
    setSelectedDataTrackTypes(types);
  }

  return { selectedDataTrackTypes, toggleDataTrackType, setDataTrackTypeSelection };
}

export function getDataColors(): string[] {
  return palettes.categorical;
}

/**
 * align @see dataPoints relative to @see referenceTime
 * @param referenceTime timestamp used to align data point timestamps
 * @param dataPoints array of data points to be aligned
 */
export const calculateRelativeTimestamps = (referenceTime: number, dataPoints: DataPointObject[]) =>
  dataPoints.map(({ ts, v }) => ({ v, ts: ts - referenceTime }));
