/**
 * react query cache keys
 */
const enum QKey {
  // analysis
  PROCESS_RECORDS = 'process-records',
  /** used for data tracks that only exist client side (e.g. calculated data tracks that have not been saved) */
  LOCAL_DATA_TRACKS = 'local-data-tracks',
  EVENTS = 'events',

  /** mutation key for data track parsing */
  PARSED_DATA_TRACK = 'parsed-data-track',

  /** mutation key for data track data points */
  DATA_TRACK_DATA_POINTS = 'data-track-data-points',

  /** mutation key for data track calculations */
  CALCULATED_DATA_TRACK = 'calculated-data-track',

  /** mutation key for data track submissions */
  EXTERNAL_DATA_TRACK = 'external-data-track',

  /** query key for all process record data tracks */
  ANALYTICS_DATA_TRACK = 'analytics-data-track',

  // recipe-optimization
  RECIPE_OPTIMIZATION = 'recipe-optimization',

  // monitoring
  SETUPS = 'setups',
  DATA_POINTS = 'data-points',
  CUSTOM_DATA_TRACKS = 'custom-data-tracks',
  DATA_TRACK = 'data-track',
  EMAIL = 'email',
  DWC_EVENTS = 'dwc-events',

  // user-mangement
  ORGANIZATIONS = 'organizations',
  DATAHOWLAB_CONFIGURATION = 'datahowlab-configuration',
  MEMBERS = 'members',
  SUBSCRIPTIONS = 'subscriptions',

  // global
  CONFIG = 'config',
  FEATURE = 'feature-flag',
  MAX_CANVAS_SIZE = 'max-canvas-size',
  DOCUMENTATION = 'documentation',
  CHANGELOG = 'changelog',
}

export default QKey;
