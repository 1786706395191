import { QueryClient } from '@tanstack/react-query';

import { retryStrategy } from './query-client.helpers';
import { QUERY_STALE_TIME } from './query-client.definitions';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // reduce number of refreshes
      staleTime: QUERY_STALE_TIME,

      // don't refetch when working on a local build
      refetchOnWindowFocus: !(!process.env.NODE_ENV || process.env.NODE_ENV === 'development'),

      // retry every half a second
      retryDelay: 500,

      // override default retry behavior
      retry: retryStrategy,
    },
  },
});

export default queryClient;
