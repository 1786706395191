import { Series as SeriesSplitChart } from '../../../../../shared/charts/split-chart';
import { GroupName } from '../../../../../shared/charts/chart-groups';

// eslint-disable-next-line import/prefer-default-export -- helper file
export function createSeriesGroups(
  data: Array<SeriesSplitChart>,
): Record<GroupName, Array<string>> {
  return data.reduce((acc: Record<string, Array<string>>, item: SeriesSplitChart) => {
    acc[item.title] = acc[item.title] || [];
    acc[item.title].push(item.name);
    return acc;
  }, {});
}
