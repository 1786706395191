import { useQueries, useQueryClient, UseQueryOptions, UseQueryResult } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { DataTrack, StructuredProcessRecordObject } from '../../types/process-record';
import QKey from '../keys';
import useClient from '../use-client';
import { PrsException } from '../../types/generated/prs.generated';
import { transformProcessRecordObject } from '../../types/process-record/transformers/process-record-dto-transformer';

import { UseMultipleProcessRecordsInput } from './use-multiple-process-record.definitions';
import { mapFlagsToGetProcessRecordIdParameters } from './use-multiple-process-records.helpers';

export default function useMultipleProcessRecords(
  params: UseMultipleProcessRecordsInput,
): UseQueryResult<StructuredProcessRecordObject, PrsException>[] {
  const queryClient = useQueryClient();
  const { acquireAccessToken } = useAuthentication();
  const { prsClient } = useClient();

  return useQueries({
    queries: params.map<UseQueryOptions<StructuredProcessRecordObject, Error>>(
      ([processRecordId, maybeDataTrackTypes]) => ({
        queryKey: [QKey.PROCESS_RECORDS, processRecordId],
        queryFn: async () => {
          // fetch
          prsClient.authorize(await acquireAccessToken());

          const dataTracksInput = mapFlagsToGetProcessRecordIdParameters(maybeDataTrackTypes);

          const response = await prsClient.getProcessRecordById(dataTracksInput, processRecordId);

          // transform
          const transformed = transformProcessRecordObject(response);

          // update data tracks cache
          transformed.dataTracks
            // filter out data tracks that don't have data points
            .filter((dataTrack): dataTrack is DataTrack => dataTrack.dataPoints !== undefined)
            .forEach((dataTrack) =>
              queryClient.setQueryData<DataTrack>(
                [QKey.ANALYTICS_DATA_TRACK, processRecordId, dataTrack.dataTrackId],
                () => dataTrack,
              ),
            );

          return transformed;
        },
      }),
    ),
  });
}
