import React, { useState } from 'react';
import { ActivityIndicator, DATA_COLORS } from '@biss/react-horizon-web';

import { SeriesMarkLine, SeriesName } from '../../../../../shared/components/time-series-chart';
import {
  DataTrackTimeAlignment,
  MarkedDataTrack,
} from '../process-record-visualization.definitions';
import { useDataTrackColorization } from '../process-record-visualization.helpers';
import { YAxisRange } from '../../../../../shared/components/time-series-chart/y-axis-range-inputs/y-axis-range-inputs.validation';
import useMultipleAnalyticsDataTracks, {
  UseMultipleAnalyticsDataTracksInput,
} from '../../../../../shared/common/hooks/use-multiple-analytics-data-tracks';
import { DataTrack } from '../../../../../shared/common/types/process-record';
import ProcessRecordVisualizationChartSelection from '../process-record-visualization-chart-selection';
import { DataTrackType } from '../../../../../shared/common/types/setup';

import {
  ProcessRecordVisualizationChartProps,
  YAXIS_LABEL_DECIMALS,
} from './process-record-visualization-chart.definitions';
import {
  calculateDataTrackDataPoints,
  calculateInoculationTime,
  calculateOldestDataPointTimestamp,
  calculateYoungestDataPointTimestamp,
  useFormatXAxisTicks,
  useRelativeXAxisTimestamps,
} from './process-record-visualization-chart.helpers';

function ProcessRecordVisualizationChart({
  coloredProcessRecords,
  timeAlignment,
  xAxisFormat,
  inSingleView,
  selectedDataTrackTypes,
  defaultYAxisRanges,
  chartType,
}: ProcessRecordVisualizationChartProps) {
  // date formatters
  const dateFormatter = useFormatXAxisTicks();
  const relativeDateFormatter = useRelativeXAxisTimestamps();

  // axis formatters
  const xAxisFormatter =
    timeAlignment === DataTrackTimeAlignment.Absolute ? dateFormatter : relativeDateFormatter;
  const yAxisFormatter = (value: number) => parseFloat(value.toFixed(YAXIS_LABEL_DECIMALS));

  // range of the viewable and zoom-able area on the y axis of the chart
  const [yAxisRanges, setYAxisRanges] = useState<Map<SeriesName, YAxisRange>>(
    defaultYAxisRanges ?? new Map(),
  );

  // update local yAxisRange state
  const handleYAxisRangeChange = (newRange: YAxisRange, seriesType: SeriesName) => {
    setYAxisRanges((prev) => {
      const newMap = new Map(prev);
      newMap.set(seriesType, newRange);
      return newMap;
    });
  };

  // calculate fetch-able data tracks
  const dataTracksToBeFetchedAndVisualized = coloredProcessRecords.reduce(
    (acc, processRecord) =>
      acc.concat(
        processRecord.dataTracks
          .filter((dt) => selectedDataTrackTypes.includes(dt.dataTrackType))
          .map((dataTrack) => [processRecord.processRecordId, dataTrack.dataTrackId]),
      ),
    [] as UseMultipleAnalyticsDataTracksInput,
  );

  // fetch data tracks, or load them from the cache
  const dataTrackQueries = useMultipleAnalyticsDataTracks(dataTracksToBeFetchedAndVisualized);
  const fetchedDataTracks: DataTrack[] = dataTrackQueries.flatMap((query) =>
    query.data !== undefined && query.isLoading === false ? query.data : [],
  );
  const isLoadingDataTracks = dataTrackQueries.some((query) => query.isLoading);

  // data track colorization utility
  const { getColor } = useDataTrackColorization(selectedDataTrackTypes);

  // mark every fetched data track with a reference to its process record
  // colorize data tracks
  // align data track data points relative
  const markedDataTracks = coloredProcessRecords.reduce<MarkedDataTrack[]>((acc, processRecord) => {
    const currentProcessRecordDataTrackIds = processRecord.dataTracks.map((qDt) => qDt.dataTrackId);
    const currentProcessRecordFetchedDataTracks: MarkedDataTrack[] = fetchedDataTracks
      // get the current process records fetched data tracks
      .filter((dt) => currentProcessRecordDataTrackIds.includes(dt.dataTrackId))
      .map(
        (dt) =>
          ({
            ...dt,
            // mark data track
            processRecord,
            color: inSingleView ? getColor(dt.dataTrackType) : processRecord.color,
            // align data points
            dataPoints: calculateDataTrackDataPoints(
              dt.dataPoints,
              timeAlignment,
              processRecord.startTimestamp,
              processRecord.inoculationTimestamp,
            ),
          }) satisfies MarkedDataTrack,
      );

    return acc.concat(currentProcessRecordFetchedDataTracks);
  }, []);

  // map selected data track types to data tracks with the same type
  // if no data tracks are available with the chosen type, ignore the type
  const selectedTypeToMarkedDataTracks: Record<DataTrackType, MarkedDataTrack[]> =
    Object.fromEntries(
      selectedDataTrackTypes.flatMap((type) => {
        const dataTracksOfType = markedDataTracks.filter((dt) => dt.dataTrackType === type);
        return dataTracksOfType.length > 0 ? [[type, dataTracksOfType]] : [];
      }),
    );

  // data tracks whose type is selected
  const selectedMarkedDataTracks = Object.values(selectedTypeToMarkedDataTracks).flat();

  // configure mark lines
  const markLines = coloredProcessRecords.reduce<SeriesMarkLine[]>((acc, pr) => {
    const timestamp = calculateInoculationTime(
      timeAlignment,
      pr.startTimestamp.getTime(),
      pr.inoculationTimestamp?.getTime(),
    );

    if (timestamp === undefined) {
      return acc;
    }

    return [
      ...acc,
      {
        color: inSingleView ? DATA_COLORS.gray : pr.color,
        name: 'Inoculation',
        timestamp,
      } satisfies SeriesMarkLine,
    ];
  }, []);

  const startTime = calculateOldestDataPointTimestamp(selectedMarkedDataTracks);
  const stopTime = calculateYoungestDataPointTimestamp(selectedMarkedDataTracks);

  return (
    <>
      {isLoadingDataTracks && (
        <div className="px-4">
          <ActivityIndicator showLabel label="Loading data track(s)" />
        </div>
      )}

      <ProcessRecordVisualizationChartSelection
        chartType={chartType}
        xAxisFormat={xAxisFormat}
        //
        series={selectedTypeToMarkedDataTracks}
        startTime={startTime}
        stopTime={stopTime}
        xAxisFormatter={xAxisFormatter}
        yAxisFormatter={yAxisFormatter}
        yAxisRanges={yAxisRanges}
        onYAxisRangeChange={handleYAxisRangeChange}
        setYAxisRanges={setYAxisRanges}
        seriesMarkLines={markLines}
        showToggleSplit={inSingleView}
      />
    </>
  );
}

export default ProcessRecordVisualizationChart;
