import React, { FunctionComponentElement, useEffect, useRef, useState } from 'react';

import EventList from '../../../../../shared/components/event-list';
import {
  useSetupEvents,
  useSetupEventsLastTimestamp,
} from '../../../../common/hooks/use-setup-events';
import { EVENTS_FROM_TIMESTAMP_INTERVAL } from '../../../../common/types/setup';

import { Event } from '../../../../../shared/common/types/event';

import useFeatureFlag from '../../../../../shared/common/hooks/use-feature-flag';

import ReleaseFeatureFlag from '../../../../../shared/common/types/release-feature-flag';

import FKey from '../../../../../shared/common/feature-keys';

import { SetupEventListProps } from './setup-event-list.definitions';
import { sortEvents, addOneMillisecond } from './setup-event-list.helpers';
import SetupEventListSubscription from './setup-event-list-subscription';

function SetupEventList({
  systemId,
  startTimestamp,
  autoUpdate,
  unit,
  stopTimestamp,
  isLoadingSetup,
  setupId,
  controlProcedureId,
  isSubscribedToDwcEvents,
}: SetupEventListProps): FunctionComponentElement<SetupEventListProps> | null {
  const startTime = startTimestamp.toISOString();
  const stopTime = stopTimestamp?.toISOString();
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null);
  const lastTimestampRef = useRef<string>();
  const [events, setEvents] = useState<Event[]>([]);
  const isDwcEventNotificationEnabled = useFeatureFlag(
    new ReleaseFeatureFlag(FKey.MONITORING_DWC_EVENT_NOTIFICATION),
  );

  const {
    data: eventList,
    isLoading,
    isSuccess,
  } = useSetupEvents(systemId, startTime, unit, stopTime);

  const { getLastEvents } = useSetupEventsLastTimestamp();

  const getEvents = async () => {
    try {
      if (lastTimestampRef.current) {
        const data = await getLastEvents(systemId, lastTimestampRef.current, unit);

        if (data?.length) {
          setEvents((prev) => sortEvents([...data, ...prev]));
        }
      }
    } finally {
      timeoutRef.current = setTimeout(() => {
        getEvents();
      }, EVENTS_FROM_TIMESTAMP_INTERVAL);
    }
  };

  useEffect(() => {
    if (autoUpdate && !stopTimestamp) {
      getEvents();
    }
    if ((!autoUpdate || stopTimestamp) && timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [autoUpdate, stopTimestamp]);

  useEffect(() => {
    const eventListSorted = sortEvents(eventList);
    setEvents(eventListSorted);
  }, [eventList]);

  useEffect(() => {
    if (events.length) {
      // first timestamp is taken because the events are sorted from last to first,
      // the timestamp in the database has greater precision
      // to avoid the duplicates add 1 milliseconds to the last timestamp
      lastTimestampRef.current = addOneMillisecond(events[0].timestamp);
    }
  }, [events]);

  // do not show list while setup is loading
  if (isLoadingSetup) {
    return null;
  }
  return (
    <EventList
      footer={
        isDwcEventNotificationEnabled && (
          <SetupEventListSubscription
            controlProcedureId={controlProcedureId}
            isSubscribedToDwcEvents={isSubscribedToDwcEvents}
            setupId={setupId}
            systemId={systemId}
          />
        )
      }
      data={isSuccess ? { events, state: 'success' } : { state: isLoading ? 'loading' : 'error' }}
    />
  );
}

export default SetupEventList;
