import React, { FunctionComponentElement, useState } from 'react';
import { useMediaQuery, useReadLocalStorage } from 'usehooks-ts';
import { Button, Tile } from '@biss/react-horizon-web';

import { FormattedMessage } from 'react-intl';

import { useFormatDateTime } from '../../../../shared/common/hooks/use-format-time';
import TimeSeriesChart, { SeriesName } from '../../../../shared/components/time-series-chart';
import {
  SHORTCUTS,
  Y_AXIS_TIP,
} from '../../../../shared/components/time-series-chart/time-series-chart.definitions';

import {
  getSeriesLegend,
  yAxisFormatter,
} from '../setup-details-content/setup-details-content.helpers';

import TileWithSkeleton from '../../../../shared/tile-with-skeleton';

import useFeatureFlag from '../../../../shared/common/hooks/use-feature-flag';
import ReleaseFeatureFlag from '../../../../shared/common/types/release-feature-flag';
import FKey from '../../../../shared/common/feature-keys';

import { Y_AXIS_RANGE } from '../../../common/types/setup';

import { YAxisRange } from '../../setup-overview/setup-overview-y-axis/y-axis-range-controls/y-axis-range-controls.validation';

import {
  CHART_VIEW,
  ChartView,
} from '../../../../shared/charts/chart-toolbox/chart-toolbox.definitions';

import { SetupTimeSeriesChartProps } from './setup-time-series-chart.definitions';
import { getYAxisRanges } from './setup-time-series-chart.helpers';
import SetupTimeSeriesChartVisualization from './setup-time-series-chart-visualization.tsx';

function SetupTimeSeriesChart({
  startTimestamp,
  stopTimestamp,
  selectedDataTracks,
  seriesLegendSelected,
  seriesMarkLines,
  toggleSeriesLegendSelected,
  updateDataTrackColors,
  isShowLoading,
  series,
  dataTracks,
  defaultIsCombinedGraph = true,
  seriesData,
}: SetupTimeSeriesChartProps): FunctionComponentElement<SetupTimeSeriesChartProps> {
  const xAxisFormatter = useFormatDateTime();
  const isMobile = useMediaQuery('(max-width: 768px)');

  const storageYAxis = useReadLocalStorage<Record<SeriesName, YAxisRange>>(Y_AXIS_RANGE);

  // range of the viewable and zoom-able area on the y axis of the chart
  const [yAxisRanges, setYAxisRanges] = useState<Map<SeriesName, YAxisRange>>(
    storageYAxis ? getYAxisRanges(storageYAxis) : new Map(),
  );

  // TODO: will be removed in scope of BIOCL-4875
  const showRefactoredChartComponent = useFeatureFlag(
    new ReleaseFeatureFlag(FKey.MONITORING_REFACTORING_SPLIT_CHART),
  );
  const [combinedGraph, setCombinedGraph] = useState<boolean>(defaultIsCombinedGraph);
  const [showNewChart, setShowNewChart] = useState<boolean>(false);
  const toggleSplitView = (isCombined: boolean) => {
    setCombinedGraph(isCombined);
    if (isCombined) {
      updateDataTrackColors();
    }
  };
  //

  const [chartView, setChartView] = useState<ChartView>(CHART_VIEW[0]);

  const seriesLegend = getSeriesLegend(dataTracks, selectedDataTracks);
  const stopTime = stopTimestamp?.getTime() || new Date().getTime();

  const handleYAxisRangeChange = (newRange: YAxisRange, seriesType: SeriesName) => {
    setYAxisRanges((prev) => {
      const newMap = new Map(prev);
      newMap.set(seriesType, newRange);
      return newMap;
    });
  };

  return (
    <TileWithSkeleton
      className={`${isMobile ? 'min-h-[383px]' : 'min-h-[507px]'} p-4`}
      isLoading={isShowLoading}
      data-testid="detail-graph"
    >
      {startTimestamp && (
        <>
          {/* TODO: will be removed in scope of BIOCL-4875 */}
          <TimeSeriesChart
            series={series}
            startTime={startTimestamp.getTime()}
            stopTime={stopTime}
            combinedGraph={combinedGraph}
            showTooltip
            xAxisFormatter={xAxisFormatter}
            yAxisFormatter={yAxisFormatter}
            seriesMarkLines={seriesMarkLines}
            toggleSplitView={toggleSplitView}
            showToggleSplit
            showZoom
            showToolbox
            showLegend={combinedGraph}
            seriesLegend={seriesLegend}
            legendSelected={seriesLegendSelected}
            toggleLegendSelected={toggleSeriesLegendSelected}
            yAxisRanges={yAxisRanges}
            onYAxisRangeChange={handleYAxisRangeChange}
            setYAxisRanges={setYAxisRanges}
            showSideToolbox
            showDetailModal
          />
          <Tile.Footer>
            {SHORTCUTS}
            {Y_AXIS_TIP}
          </Tile.Footer>
        </>
      )}
      {/* temporary button to show or hide new chart components */}
      <Button
        onClick={() => setShowNewChart(!showNewChart)}
        className={`w-48 ${showRefactoredChartComponent ? 'block' : 'hidden'}`}
      >
        <FormattedMessage
          description="Show/Hide New chart button"
          defaultMessage="Show/Hide New chart"
          id="Wk6ddq"
        />
      </Button>
      {showNewChart && (
        <SetupTimeSeriesChartVisualization
          startTimestamp={startTimestamp?.getTime()}
          seriesMarkLines={seriesMarkLines}
          seriesData={seriesData}
          stopTimestamp={stopTime}
          yAxisRanges={yAxisRanges}
          setYAxisRanges={setYAxisRanges}
          chartView={chartView}
          setChartView={setChartView}
        />
      )}
    </TileWithSkeleton>
  );
}

export default SetupTimeSeriesChart;
