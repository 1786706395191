import { useQueries, UseQueryOptions } from '@tanstack/react-query';
import { useAuthentication } from '@biss/react-auth-web';

import { DataTrack } from '../../types/process-record';
import { PrsException } from '../../types/generated/prs.generated';
import QKey from '../keys';
import useClient from '../use-client';

import { QUERY_STALE_TIME } from '../../query-client/query-client.definitions';

import {
  DWC_DATA_TRACK_STALE_TIME,
  UseMultipleAnalyticsDataTracksInput,
} from './use-multiple-analytics-data-tracks.definitions';

/**
 * fetches and stores multiple data tracks from different process records
 * @param processRecordToDataTracks - map of process record id to multiple data track ids
 */
export default function useMultipleAnalyticsDataTracks(
  processRecordToDataTracks: UseMultipleAnalyticsDataTracksInput,
) {
  const { acquireAccessToken } = useAuthentication();
  const { prsClient } = useClient();

  return useQueries({
    queries: processRecordToDataTracks
      // create queries
      .map<UseQueryOptions<DataTrack, PrsException>>(([processRecordId, dataTrackId]) => ({
        queryKey: [QKey.ANALYTICS_DATA_TRACK, processRecordId, dataTrackId],
        queryFn: async () => {
          // fetch
          prsClient.authorize(await acquireAccessToken());
          return prsClient.getDataTrackById(processRecordId, dataTrackId);
        },
        staleTime: (query) => {
          // refetch dwc-sent data tracks less often as they do not change
          if (query.state.data?.isCustom === false) {
            return DWC_DATA_TRACK_STALE_TIME;
          }

          return QUERY_STALE_TIME;
        },
      })),
  });
}
