import { UseQueryResult } from '@tanstack/react-query';

import { StructuredProcessRecordObject } from '../../types/process-record';
import { useMultipleProcessRecords } from '../use-multiple-process-records';

import { UseProcessRecordInput } from './use-process-record.definitions';

export default function useProcessRecord({
  processRecordId,
  withDataPointsOf: dataTrackTypes,
}: UseProcessRecordInput): UseQueryResult<StructuredProcessRecordObject, Error> {
  return useMultipleProcessRecords([[processRecordId, dataTrackTypes]])[0];
}
