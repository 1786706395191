import React from 'react';
import { Modal } from '@biss/react-horizon-web';
import { FormattedMessage } from 'react-intl';

import { DataTrackEmptyModalSuccessProps } from './data-track-empty-modal.definitions';

function DataTrackEmptyModalSuccess({ open, onOpenChange }: DataTrackEmptyModalSuccessProps) {
  return (
    <Modal
      open={open}
      onOpenChange={onOpenChange}
      title={
        <FormattedMessage
          defaultMessage="Create Empty Data Track"
          id="pB37vX"
          description="Create Empty Data Track: Title"
        />
      }
    >
      <Modal.Content>
        <FormattedMessage
          description="Calculate Data Track Success Message"
          defaultMessage="Data Track was successfully created. Select it from the Data Tracks List to view it in the graph."
          id="IZGthd"
        />
      </Modal.Content>
      <Modal.ButtonGroup>
        <Modal.Close asChild>
          <Modal.Button>
            <FormattedMessage
              defaultMessage="Close"
              id="4SRIi7"
              description="Create Empty Data Track: Close"
            />
          </Modal.Button>
        </Modal.Close>
      </Modal.ButtonGroup>
    </Modal>
  );
}

export default DataTrackEmptyModalSuccess;
