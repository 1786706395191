import React, { ReactNode } from 'react';
import { IntlShape } from 'react-intl';
import {
  IndicatorAlertIcon,
  IndicatorErrorIcon,
  IndicatorNoticeIcon,
  IndicatorWarningIcon,
} from '@biss/react-horizon-web';

import { ProcessRecordEventLevel } from '../../common/types/generated/prs.generated';

import { EventListItem, EventState } from './event-list.definitions';

export const seekEvent = (
  query: string,
  {
    id,
    timestamp,
    message,
    logicalReferenceComment,
    physicalReferenceComment,
    actorName,
    eventType,
    level,
    applicationName,
    applicationVersion,
  }: EventListItem,
  intl: IntlShape,
) => {
  const queryLower = query.toLocaleLowerCase();

  if (message.toLowerCase().includes(queryLower)) {
    return true;
  }

  if (
    intl
      .formatDate(timestamp, { year: 'numeric', month: 'short', day: 'numeric' })
      .toLocaleLowerCase()
      .replaceAll(' ', '')
      .includes(queryLower.replaceAll(' ', ''))
  ) {
    return true;
  }

  if (
    intl
      .formatTime(timestamp, { hour: 'numeric', minute: 'numeric', second: 'numeric' })
      .includes(queryLower)
  ) {
    return true;
  }

  if (
    [logicalReferenceComment, physicalReferenceComment, actorName]
      .join()
      .toLocaleLowerCase()
      .includes(queryLower)
  ) {
    return true;
  }

  if (
    [eventType, level, applicationName ?? '', applicationVersion ?? '']
      .join()
      .toLocaleLowerCase()
      .includes(queryLower)
  ) {
    return true;
  }

  if (id.includes(queryLower)) {
    return true;
  }

  return false;
};

export const iconMap: Record<ProcessRecordEventLevel, ReactNode | null> = {
  Fatal: (
    <div className="danger">
      <IndicatorErrorIcon />
    </div>
  ),
  Alarm: <IndicatorAlertIcon className="text-danger" />,
  Error: <IndicatorAlertIcon className="text-danger" />,
  Warn: <IndicatorWarningIcon className="text-warning" />,
  Info: <IndicatorNoticeIcon className="text-positive" />,
  Unknown: null,
  Debug: null,
  Notice: null,
};

export function formatTitles(intl: IntlShape) {
  return {
    level: null,
    time: intl.formatMessage({ defaultMessage: 'Date', description: 'Event time', id: 'LqdiUf' }),
    description: intl.formatMessage({
      defaultMessage: 'Description',
      description: 'Event description',
      id: 'zZdxNi',
    }),
    reference: intl.formatMessage({
      defaultMessage: 'Reference',
      description: 'Event reference',
      id: 'EYeCBo',
    }),
    resource: intl.formatMessage({
      defaultMessage: 'Resource',
      description: 'Event resource',
      id: 'be0JHK',
    }),
    user: intl.formatMessage({ defaultMessage: 'User', description: 'Event user', id: '8H2yBs' }),
  } as const;
}

/** Indicates whether the given event data contains at least one event or not. */
export function eventDataContainsEvents(data: EventState) {
  return data.state === 'success' && data.events.length > 0;
}
