import React, { FunctionComponentElement } from 'react';

import { FormattedMessage } from 'react-intl';

import Tile from '../../../../components/tile';
import DataTrackValue from '../../../../components/data-track-value';

import { AlertLimitsModal } from '../../alert-limits-modal';

import ReleaseFeatureFlag from '../../../../../shared/common/types/release-feature-flag';
import useFeatureFlag from '../../../../../shared/common/hooks/use-feature-flag';
import FKey from '../../../../../shared/common/feature-keys';

import ProcessValueIndicator from './process-value-indicator';
import { ProcessValueTileProps } from './process-value-tile.definitions';

function ProcessValueTile({
  processValue,
  processValueType,
  isDisconnected,
}: ProcessValueTileProps): FunctionComponentElement<ProcessValueTileProps> {
  const variant = isDisconnected ? 'error' : 'default';
  const isSetAlertLimitsEnabled = useFeatureFlag(
    new ReleaseFeatureFlag(FKey.MONITORING_SET_TRACK_ALERT_LIMITS),
  );
  return (
    <Tile color={processValue.color} variant={variant} testId="process-value-tile">
      <div className="flex w-full flex-col text-lg font-bold text-secondary">
        <div className="flex w-full flex-row justify-between">
          <div data-testid="process-value-type">{processValueType}</div>
          {isDisconnected && <ProcessValueIndicator />}
          {isSetAlertLimitsEnabled && !isDisconnected && (
            <div className="flex items-center gap-2">
              <AlertLimitsModal
                title={
                  <FormattedMessage
                    values={{
                      processValueType,
                    }}
                    defaultMessage="Set {processValueType} Alert"
                    id="FXLEFp"
                    description="Title of set alert limits modal."
                  />
                }
              />
            </div>
          )}
        </div>
        <div className="flex items-end justify-between">
          <span data-testid="process-value-setpoint">
            {processValue.setPoint && (
              <DataTrackValue
                value={processValue.setPoint?.value}
                engineeringUnit={processValue.setPoint?.engineeringUnit}
                fractionalDigits={processValue.setPoint?.fractionalDigits}
              />
            )}
          </span>
          <span
            data-testid="process-value"
            className={`text-3xl font-bold ${isDisconnected ? 'text-secondary' : 'text-primary'}`}
          >
            {processValue.processValue && (
              <DataTrackValue
                value={processValue.processValue?.value}
                engineeringUnit={processValue.processValue?.engineeringUnit}
                fractionalDigits={processValue.processValue?.fractionalDigits}
              />
            )}
          </span>
        </div>
      </div>
    </Tile>
  );
}

export default ProcessValueTile;
