/**
 * App config feature keys
 */
const enum FKey {
  // monitoring-detail-view
  MONITORING_DETAIL_VIEW_PROTOTYPING = 'BIOCL-2237/monitoring-detail-view-prototyping',

  MONITORING_ADD_EDIT_OFFLINE_DATA = 'rel_add-edit-offline-data',
  MONITORING_SET_TRACK_ALERT_LIMITS = 'rel_set-track-alert-limits',

  MONITORING_DWC_EVENT_NOTIFICATION = 'rel_dwc-event-notification',

  MONITORING_REFACTORING_SPLIT_CHART = 'dev_BIOCL-4458/refactor-split-chart-component',

  // process-record-visualization
  ANALYTICS_REFACTORING_COMBINED_CHART = 'dev_BIOCL-4457/refactor-combined-chart-component',

  /** Whether users can edit custom data tracks. */
  ANALYTICS_EDIT_DATA_POINTS = 'rel_BIOCL-4202/edit-custom-data-tracks-in-analytics',

  /** this key is only used within tests */
  TEST_KEY = 'TEST_KEY',
}

export default FKey;
