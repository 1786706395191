import React, { useRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { DeleteIcon, EditIcon, List, Modal, SaveIcon } from '@biss/react-horizon-web';
import cn from 'classnames';

import { DataTrackListItemProps } from './data-track-list-item.definitions';

/**
 * A single list item in the datatrack list
 */
function DataTrackListItem({
  trackInfo,
  isSelected = false,
  isDisabled = false,
  onSelect,
  isDeletable = false,
  onDelete,
  isSavable = false,
  onSave,
  defaultOpen,
  isCreateDataPoints,
  onCreateCustomDataPoints,
}: DataTrackListItemProps) {
  const { dataTrackType, engineeringUnit, dots = [] } = trackInfo;

  const deleteButtonRef = useRef<HTMLButtonElement>(null);

  const handleClick = (
    e: React.MouseEvent<HTMLLIElement, MouseEvent> | React.KeyboardEvent<HTMLLIElement>,
  ) => {
    // do nothing when clicked on the delete button
    if ([...e.nativeEvent.composedPath()].some((item) => item === deleteButtonRef.current)) {
      return;
    }

    onSelect(dataTrackType, !isSelected);
  };

  const handleConfirmDelete = () => onDelete?.(trackInfo);

  return (
    <List.Item
      lining="underline"
      select="multi"
      isSelected={isSelected}
      disabled={isDisabled}
      clickable
      onClick={handleClick}
      className="group"
      actions={
        (isCreateDataPoints || isDeletable || isSavable) && (
          <List.Item.ActionArea stopPropagation>
            <div className="flex translate-x-5 gap-x-3 pr-4 md:gap-1.5 lg:pr-0 lg:opacity-0 lg:transition-all lg:ease-in lg:group-hover:visible lg:group-hover:translate-x-0 lg:group-hover:opacity-100">
              {isSavable && (
                <button
                  data-testid="save-data-track-button"
                  type="button"
                  className="h-6 w-6 text-accent"
                  onClick={() => onSave?.(trackInfo)}
                >
                  <SaveIcon />
                </button>
              )}
              {isCreateDataPoints && (
                <button
                  data-testid="create-data-points-button"
                  type="button"
                  className="h-6 w-6 text-accent"
                  onClick={() => onCreateCustomDataPoints?.(dataTrackType)}
                >
                  <EditIcon />
                </button>
              )}
              {isDeletable && (
                <Modal
                  defaultOpen={defaultOpen}
                  trigger={
                    <button
                      data-testid="remove-datatrack-button"
                      type="button"
                      className="h-6 w-6 text-gray-800"
                    >
                      <DeleteIcon />
                    </button>
                  }
                  title={
                    <FormattedMessage
                      description="Data Track Delete Modal: title for delete modal"
                      defaultMessage="Confirm Deletion"
                      id="HWBg1E"
                    />
                  }
                >
                  <Modal.Content>
                    <FormattedMessage
                      description="Data Track Delete Modal: description for delete modal"
                      defaultMessage={`The data track with the name "{dataTrackType}" will be deleted from BioNsight cloud. This action cannot be undone. Are you sure you want to delete this data track?`}
                      values={{
                        dataTrackType,
                      }}
                      id="fd3ZYz"
                    />
                  </Modal.Content>
                  <Modal.ButtonGroup>
                    <Modal.Close asChild role="button">
                      <Modal.Button>
                        <FormattedMessage
                          description="Data Track Delete Modal Cancel Button: label for cancel button"
                          defaultMessage="Cancel"
                          id="sKTpAB"
                        />
                      </Modal.Button>
                    </Modal.Close>
                    <Modal.Button
                      variant="destructive"
                      onClick={handleConfirmDelete}
                      data-testid="confirmDelete"
                    >
                      <FormattedMessage
                        description="Data Track Delete Modal Delete Button: label for delete button"
                        defaultMessage="Delete"
                        id="NEOiR8"
                      />
                    </Modal.Button>
                  </Modal.ButtonGroup>
                </Modal>
              )}
            </div>
          </List.Item.ActionArea>
        )
      }
    >
      <List.Item.Label weight="bold" className="break-all" data-testid="data-track-item-type">
        {dataTrackType}
      </List.Item.Label>
      <List.Item.Label
        weight="light"
        className={
          // ci arranges classnames differently than some local
          cn('ml-2', 'whitespace-nowrap', 'text-nowrap')
        }
      >
        {engineeringUnit && (
          <FormattedMessage
            description="data track list item: engineering unit"
            defaultMessage="({engineeringUnit})"
            values={{
              engineeringUnit,
            }}
            id="b6Nxf0"
          />
        )}
      </List.Item.Label>
      <div className="ml-auto grid grid-cols-[1fr]">
        {dots.length > 0 && (
          <div
            className={cn('col-start-1 row-start-1 my-auto flex flex-row-reverse gap-1.5 pl-2', {
              'opacity-100 transition-opacity ease-out group-hover:opacity-0': isDeletable,
            })}
            data-testid="DataTrackColors"
          >
            {dots.map((color) => (
              <div
                key={color}
                className="h-1.5 w-1.5 rounded-full"
                style={{ backgroundColor: color }}
              />
            ))}
          </div>
        )}
      </div>
    </List.Item>
  );
}

export default DataTrackListItem;
