import { MarkLine } from './chart-markline';

/**
 * Echarts cache keys.
 */
export function getDataCacheKey(
  data: Array<{ dataPoints: Array<{ ts: number; v: number }>; id: string }>,
): string {
  return data
    .map((entry) => [
      entry.id,
      entry.dataPoints.length,
      entry.dataPoints.at(0)?.ts,
      entry.dataPoints.at(0)?.v,
      entry.dataPoints.at(-1)?.ts,
      entry.dataPoints.at(-1)?.v,
    ])
    .join()
    .concat(data.length.toString());
}

export function getMarkLinesCacheKey(markLines: MarkLine[]): string {
  return markLines
    .map((entry) => [entry.name, entry.timestamp])
    .join()
    .concat(markLines.length.toString());
}

/**
 * Echarts configuration for the toolbox.
 *
 * saveAsImage: allows users to save the current chart as an image.
 */
export function getToolboxEchartsConfig() {
  return {
    show: true,
    feature: {
      saveAsImage: {
        name: 'BioNsight',
        pixelRatio: 2,
      },
    },
  };
}
