import { z } from 'zod';

const requiredError = 'A display name must be provided.';

export const dataTrackCreationInfoSchema = z.object({
  displayName: z.string({ required_error: requiredError }).trim().min(1, requiredError),
  engineeringUnit: z.string().trim().optional(),
});

export const dataTrackEmptyFormSchema = z.object({
  dataTracks: z.array(dataTrackCreationInfoSchema),
});

/**
 * @param displayNames
 * @returns the indices of duplicate entries
 */
export const checkForDuplicateDisplayNames = (displayNames: string[]): number[] =>
  displayNames.flatMap((displayName, index) =>
    displayNames.filter((otherDisplayName) => otherDisplayName === displayName).length > 1
      ? index
      : [],
  );
