import { useMutation, useQueryClient } from '@tanstack/react-query';

import { useAuthentication } from '@biss/react-auth-web';

import { DataPointObject, DataTrack } from '../../../../shared/common/types/process-record';
import QKey from '../../../../shared/common/hooks/keys';
import { PrsException } from '../../../../shared/common/types/generated/prs.generated';
import useClient from '../../../../shared/common/hooks/use-client';

function useUpdateDataTrackDataPoints(processRecordId: string, dataTrackId: string) {
  const queryClient = useQueryClient();
  const { prsClient } = useClient();
  const { acquireAccessToken } = useAuthentication();

  return useMutation<DataPointObject[], PrsException, DataPointObject[], unknown>({
    mutationKey: [QKey.DATA_TRACK_DATA_POINTS],
    mutationFn: async (dataPoints) => {
      // fetch
      prsClient.authorize(await acquireAccessToken());

      return prsClient.updateDataTrackDataPoints(dataPoints, processRecordId, dataTrackId);
    },
    onSuccess(data) {
      const queryKeyToUpdate = [QKey.ANALYTICS_DATA_TRACK, processRecordId, dataTrackId];
      const previousDataTrack = queryClient.getQueryData<DataTrack>(queryKeyToUpdate);

      if (previousDataTrack === undefined) {
        return;
      }

      queryClient.setQueryData<DataTrack>(queryKeyToUpdate, () => ({
        ...previousDataTrack,
        dataPoints: data,
      }));
    },
  });
}

export default useUpdateDataTrackDataPoints;
