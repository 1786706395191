import React from 'react';
import { FormattedMessage } from 'react-intl';

import { FileInputProps } from './file-input.definitions';

function FileInput({ handleFile }: FileInputProps) {
  const inputRef = React.useRef<HTMLInputElement>(null);

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { target } = event;
    if (!target || !inputRef.current) {
      return;
    }

    if (!target.files) {
      return;
    }

    handleFile(target.files[0]);
  };

  const handleDrop = (event: React.DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    if (!inputRef.current) {
      return;
    }

    if (event.dataTransfer?.files?.length === 0) {
      return;
    }

    handleFile(event.dataTransfer.files[0]);
  };

  return (
    <div
      role="presentation"
      onDrop={handleDrop}
      onDragOver={(event) => event.preventDefault()}
      className="flex h-96 w-full flex-col items-center justify-center rounded border border-dashed border-gray-700 bg-gray-100 p-2"
    >
      <div className="flex items-center gap-1">
        <FormattedMessage
          description="Data track file upload message"
          defaultMessage="Drag a file here or"
          id="8z60KX"
        />
        <div>
          <button
            data-testid="csv-file-chooser"
            type="button"
            onClick={() => inputRef.current?.click()}
            className="m-0 text-blue"
          >
            <FormattedMessage
              description="Data track upload link to open file chooser"
              defaultMessage="select one from my computer"
              id="+kiWHr"
            />
          </button>
          <input
            accept=".csv"
            onChange={handleFileInputChange}
            ref={inputRef}
            type="file"
            data-testid="data-track-file"
            className="hidden appearance-none"
          />
          <FormattedMessage
            description="Data track upload: period at the end of the sentence."
            defaultMessage="."
            id="hwaSxO"
          />
        </div>
      </div>
    </div>
  );
}

export default FileInput;
