/**
 * Beta feature keys
 */
enum BKey {
  // Monitoring detail view
  // this key is used in analytics as well to enable data point editing
  MONITORING_ADD_EDIT_OFFLINE_DATA_BETA_PACKAGE_NAME = 'add-edit-offline-data',

  /** this key is only used within tests */
  TEST_KEY = 'TEST-KEY',
}

export default BKey;
