import { Input } from '@biss/react-horizon-web';
import React from 'react';
import { useIntl } from 'react-intl';

import { InvitationResultProps } from './invitation-result.definitions';

function InvitationResult({ member }: InvitationResultProps) {
  const intl = useIntl();

  return (
    <div className="max-w-l m-10 hidden w-full">
      <Input
        id="memberIdInput"
        label={intl.formatMessage({
          defaultMessage: 'User ID',
          id: '+VS4v9',
          description: 'Label for memberId input.',
        })}
        type="text"
        value={member.memberId}
        readOnly
      />
    </div>
  );
}

export default InvitationResult;
