import { useQuery } from '@tanstack/react-query';
import { useAuthContext, useAuthentication } from '@biss/react-auth-web';

import QKey from '../keys';
import useLogger from '../use-logger/use-logger';
import BetaFeatureFlag from '../../types/beta-feature-flag';
import BKey from '../../beta-feature-keys';
import { fetchFeatureFlag } from '../use-feature-flag/use-feature-flag.helpers';

const useBetaFeatureFlags = (): Map<string, boolean> => {
  const logging = useLogger();
  const { acquireAccessToken } = useAuthentication();
  const { account } = useAuthContext();
  const flags = Object.values(BKey);
  const betaFlags = flags.map((flagName) => new BetaFeatureFlag(flagName, account?.organizationId));

  const queryResult = useQuery({
    queryKey: [QKey.FEATURE, 'beta'],
    queryFn: async () => {
      const accessToken = await acquireAccessToken();
      const flagValues = new Map<string, boolean>();
      await Promise.all(
        betaFlags
          .filter((flag) => flag.packgeName !== BKey.TEST_KEY)
          .map(async (betaFlag) => {
            try {
              const value = await fetchFeatureFlag(betaFlag.name, logging, accessToken);
              flagValues.set(betaFlag.packgeName, value);
            } catch {
              flagValues.set(betaFlag.packgeName, false);
            }
          }),
      );
      return flagValues;
    },
    staleTime: 300_000, // 5min
    enabled: account?.accountId !== undefined,
  });

  return queryResult.data ? queryResult.data : new Map();
};

export default useBetaFeatureFlags;
