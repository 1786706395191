import React, { FunctionComponentElement } from 'react';
import { FormattedMessage } from 'react-intl';

import InvitationInfoProps from './invitation-info.definitions';

function InvitationInfo({
  invitation,
}: InvitationInfoProps): FunctionComponentElement<InvitationInfoProps> {
  const dateTimeFormat = new Intl.DateTimeFormat(undefined, {
    dateStyle: 'medium',
    timeStyle: 'long',
  });
  return (
    <>
      <div className="my-4 w-full text-sm font-normal">
        <FormattedMessage
          defaultMessage="Invitation is still pending."
          description="Label for invitation is still pending."
          id="jaqq1w"
        />
      </div>
      <div className="my-1 w-full text-xs font-normal">
        <FormattedMessage
          defaultMessage="Invited on:"
          description="Label for Invited on."
          id="VwCJ6W"
        />
      </div>
      <div className="my-1 w-full text-sm font-bold">
        {dateTimeFormat.format(invitation.invitedAt)}
      </div>

      <div className="my-1 w-full text-xs font-normal">
        <FormattedMessage
          defaultMessage="Invitation valid until:"
          description="Label for Invitation valid until."
          id="0hiacI"
        />
      </div>

      <div className="my-1 w-full text-sm font-bold">
        {dateTimeFormat.format(invitation.validUntil)}
      </div>
    </>
  );
}

export default InvitationInfo;
