import React, { FunctionComponentElement } from 'react';
import { Tile } from '@biss/react-horizon-web';

import {
  SHORTCUTS,
  Y_AXIS_TIP,
} from '../../../../../shared/components/time-series-chart/time-series-chart.definitions';
import { CombinedChart, SplitChart } from '../../../../../shared/charts';
import PlaceholderChart from '../../../../../shared/charts/placeholder-chart';
import ChartInfoBox from '../../../../../shared/charts/chart-info-box';

import ChartToolbox from '../../../../../shared/charts/chart-toolbox';

import {
  MAX_GROUP_LENGTH,
  SetupTimeSeriesChartVisualizationProps,
} from './setup-time-series-chart-visualization.definitions';
import { createSeriesGroups } from './setup-time-series-chart-visualization.helpers';

function SetupTimeSeriesChartVisualization({
  startTimestamp,
  stopTimestamp,
  seriesMarkLines,
  chartView,
  seriesData,
  yAxisRanges,
  setYAxisRanges,
  setChartView,
}: SetupTimeSeriesChartVisualizationProps): FunctionComponentElement<SetupTimeSeriesChartVisualizationProps> | null {
  if (!startTimestamp) {
    return null;
  }

  if (!seriesData?.length) {
    return <PlaceholderChart />;
  }

  const groups = createSeriesGroups(seriesData);

  const isCombined = chartView === 'combined';

  return (
    <>
      <ChartToolbox chartView={chartView} setChartView={setChartView} />
      <ChartInfoBox
        isCombined={isCombined}
        seriesLimit={MAX_GROUP_LENGTH}
        seriesLength={Object.keys(groups).length}
      />
      {isCombined ? (
        <CombinedChart
          data={seriesData}
          groups={groups}
          startTime={startTimestamp}
          stopTime={stopTimestamp}
          markLines={seriesMarkLines}
          defaultYAxisRange={yAxisRanges}
          onYAxisRangeChange={setYAxisRanges}
        />
      ) : (
        <SplitChart
          data={seriesData}
          groups={groups}
          startTime={startTimestamp}
          stopTime={stopTimestamp}
          markLines={seriesMarkLines}
          defaultYAxisRange={yAxisRanges}
          onYAxisRangeChange={setYAxisRanges}
        />
      )}
      <Tile.Footer>
        {SHORTCUTS}
        {Y_AXIS_TIP}
      </Tile.Footer>
    </>
  );
}

export default SetupTimeSeriesChartVisualization;
