export const COMBINED_CHART_LEFT_OFFSET_PX = 42;
export const COMBINED_CHART_RIGHT_OFFSET_PX = 35;

export const COMBINED_CHART_Y_AXIS_LABEL_DECIMALS = 2;
export const COMBINED_CHART_Y_AXIS_FONT_SIZE = 12;
export const COMBINED_CHART_X_AXIS_LABEL_HEIGHT = 90;

/** Width and height of the button to reset the zoom level. */
export const COMBINED_CHART_RESET_ZOOM_BUTTON_DIMENSIONS = { width: 20, height: 20 };
export const COMBINED_CHART_X_AXIS_ZOOM_HEIGHT = 40;
export const COMBINED_CHART_GRID_HEIGHT = 270;
export const COMBINED_CHART_GRID_OFFSET = 30;
export const COMBINED_CHART_LEGEND_HEIGHT = 24;
export const COMBINED_CHART_TOTAL_HEIGHT =
  COMBINED_CHART_LEGEND_HEIGHT +
  COMBINED_CHART_GRID_OFFSET +
  COMBINED_CHART_GRID_HEIGHT +
  COMBINED_CHART_X_AXIS_LABEL_HEIGHT +
  COMBINED_CHART_X_AXIS_ZOOM_HEIGHT;
